import common from '~/common'

export default {
    data() {
        return {
            slickOptions:        {
                prevArrow:     '<button type="button" class="prev fa fa-angle-left"></button>',
                nextArrow:     '<button type="button" class="next fa fa-angle-right"></button>',
                asNavFor:      '.nav-details-slick',
                centerMode:    true,
                focusOnSelect: true,
                slidesToShow:  5,
                responsive:    [
                    {
                        breakpoint: 800,
                        settings:   {
                            slidesToShow: 3
                        }
                    }
                ]
            },
            detailsSlickOptions: {
                slidesToShow: 1,
                asNavFor:     '.nav-slick',
                arrows:       false
            }
        }
    },
    mixins: [common],
}