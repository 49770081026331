export const storeAtKeyMixin = {
    props:    {
        storeKey: {
            type:     String,
            required: true
        },
    },
    computed: {
        storeState()
        {
            return this.storeKey.split('.').reduce((acc, value) => acc[value], this.$store.state)
        },
        storeNamespace()
        {
            return this.storeKey.replace('.', '/')
        },
    },
    methods:  {
        storeCommit(mutation, payload)
        {
            this.$store.commit(this.storeNamespace + '/' + mutation, payload)
        },
        storeDispatch(action, payload)
        {
            this.$store.dispatch(this.storeNamespace + '/' + action, payload)
        },
    }
}
