import Vue from 'vue'

export default {
    props:   {
        hgls:           {
            type:     Object,
            required: true
        },
        pageNum:        {
            type:     Number,
            required: true
        },
        cntHotelsFound: {
            type:     Number,
            required: true
        },
        loading:        {
            type:     Boolean,
            required: true
        }
    },
    data()
    {
        return {
            showInitialPage:                true,
            extraPages:                     [],
            showInitialSeeAlsoSameCity:     true,   // Показывать или нет   тот же самый город
            ajaxSeeAlsoSameCity:            null,   // Компонент            тот же самый город
            showInitialSeeAlsoNeighborCity: true,   // Показывать или нет   соседний город
            ajaxSeeAlsoNeighborCity:        null,   // Компонент            соседний город
        }
    },
    mounted()
    {
        this.$el.classList.remove('hgls-items-layout-wide')     // FOUC
        this.$el.classList.remove('hgls-items-layout-grid')     // FOUC

        this.hgls.onLoadAppendData.push(data =>
        {
            data.pageNum = this.pageNum
        })

        this.hgls.onLoadSuccess.push((input, result) =>
        {
            this.showInitialSeeAlsoSameCity = false

            this.showInitialSeeAlsoNeighborCity = false

            if (result.items)
            {
                if (input.initiator !== 'showmoreButton')
                {
                    this.showInitialPage = false

                    this.extraPages = []
                }

                this.extraPages.push(Vue.compile(result.items))
            }

            if (result.seeAlsoSameCity)
            {
                this.ajaxSeeAlsoSameCity = Vue.compile(result.seeAlsoSameCity)
            }
            else
            {
                this.ajaxSeeAlsoSameCity = null
            }

            if (result.seeAlsoNeighborCity)
            {
                this.ajaxSeeAlsoNeighborCity = Vue.compile(result.seeAlsoNeighborCity)
            }
            else
            {
                this.ajaxSeeAlsoNeighborCity = null
            }
        })
    },
    watch:   {},
    methods: {
        showmoreClicked()
        {
            this.$emit('showmore-clicked')
        },
    }
}
