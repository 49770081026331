import utm from '~/partials/hotels/utm'

export default {
    namespaced: true,
    actions:    {
        openHotel(store, urlComponents)
        {
            let url = urlComponents.base

            if (urlComponents.path)
            {
                url += '/' + urlComponents.path.replace(/^\//, '')
            }

            if (urlComponents.query.length)
            {
                url += '?' + urlComponents.query.join('&')
            }

            if (urlComponents.hash)
            {
                url += '#' + urlComponents.hash
            }

            url = utm.appendLabelToUrl(url)

            if ((store.rootGetters.ua.device === 'mobile') && (['yandex', 'firefox'].includes(store.rootGetters.ua.vendor)))
            {
                url = url.replace('://booking.com', '://m.booking.com')
            }

            console.log(url)

            window.open(url)
        },
        openHotelCategory(store, urlComponents)
        {
            let url = urlComponents.base

            if (urlComponents.path)
            {
                url += '/' + urlComponents.path.replace(/^\//, '')
            }

            if (urlComponents.query.length)
            {
                url += '?' + urlComponents.query.join('&')
            }

            if (urlComponents.hash)
            {
                url += '#' + urlComponents.hash
            }

            url = utm.appendLabelToUrl(url)

            if ((store.rootGetters.ua.device === 'mobile') && (['yandex', 'firefox'].includes(store.rootGetters.ua.vendor)))
            {
                url = url.replace('://booking.com', '://m.booking.com')
            }

            console.log(url)

            window.open(url)
        },
    }
}
