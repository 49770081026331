export default {
    props:    {
        typeKey:          {
            type:     String,
            required: true
        },
        defaultDirection: {
            type:    String,
            default: ''
        }
    },
    computed: {
        isActive()
        {
            return this.typeKey === this.$parent.sorting.typeKey
        },
        hasDirection()
        {
            return ['asc', 'desc'].includes(this.defaultDirection)
        },
        directionMark()
        {
            return {asc: ' ▲', desc: ' ▼'}[this.$parent.sorting.direction]
        },
    },
}
