export default {
    bind(el, binding, vnode, oldVnode)
    {
        let oldStyle

        vnode.context.$watch(binding.expression, isModal =>
        {
            if (isModal)
            {
                oldStyle = {
                    position: el.style.position,
                    left:     el.style.left,
                    top:      el.style.top,
                    right:    el.style.right,
                    bottom:   el.style.bottom,
                    width:    el.style.width,
                    height:   el.style.height,
                    zIndex:   el.style.zIndex,
                }

                el.style.position = 'fixed'
                el.style.left = '0'
                el.style.top = '0'
                el.style.right = '0'
                el.style.bottom = '0'
                el.style.width = 'auto'
                el.style.height = 'auto'
                el.style.zIndex = 999
            }
            else
            {
                el.style.position = oldStyle.position
                el.style.left = oldStyle.left
                el.style.top = oldStyle.top
                el.style.right = oldStyle.right
                el.style.bottom = oldStyle.bottom
                el.style.width = oldStyle.width
                el.style.height = oldStyle.height
                el.style.zIndex = oldStyle.zIndex
            }
        })
    }
}