import utm from '~/partials/hotels/utm'

export default {
    computed:   {
    },
    methods:    {
        reachGoal(goalName)
        {
            setTimeout(() => window.yaCounter35155555 && window.yaCounter35155555.reachGoal(goalName))
        },
        open(url)
        {
            window.open(url)
        },
        getUtmLabel()
        {
            let from_utm = window.localStorage.getItem('from_utm')
            if (from_utm)
            {
                from_utm = JSON.parse(from_utm)
                let hoursPassed = (new Date().getTime() - from_utm.time) / 3600000
                if (hoursPassed < 48)
                {
                    return 'yd' + from_utm.campaign
                }
            }
            return null
        },
        openUrlComponents(urlComponents, addUtmParams = true)
        {
            let url = urlComponents.base

            if (urlComponents.path)
            {
                url += '/' + urlComponents.path.replace(/^\//, '')
            }

            if (urlComponents.query.length)
            {
                url += '?' + urlComponents.query.join('&')
            }

            if (urlComponents.hash)
            {
                url += '#' + urlComponents.hash
            }

            url = utm.appendLabelToUrl(url)

            console.log(url)

            window.open(url)
        },
        openWithLabel(urlComponents)
        {
            let utmLabel = this.getUtmLabel()
            if (utmLabel)
            {
                urlComponents.query.push('label=' + utmLabel)
            }
            let url = urlComponents.base
            if (urlComponents.path)
            {
                url += '/' + urlComponents.path.replace(/^\//, '')
            }
            if (urlComponents.query.length)
            {
                url += '?' + urlComponents.query.join('&')
            }
            if (urlComponents.fragment)
            {
                url += '#' + urlComponents.fragment
            }
            window.open(url)
        },
        openWithoutLabel(urlComponents)
        {
            let url = urlComponents.base
            if (urlComponents.path)
            {
                url += '/' + urlComponents.path.replace(/^\//, '')
            }
            if (urlComponents.query.length)
            {
                url += '?' + urlComponents.query.join('&')
            }
            if (urlComponents.fragment)
            {
                url += '#' + urlComponents.fragment
            }
            window.open(url)
        },
    },
    directives: {
        collapse: {
            bind(el, binding)
            {
                setTimeout(() =>
                {
                    let targets = [];
                    if (binding.modifiers.next)
                    {
                        targets.push(el.nextElementSibling)
                    }
                    switch (binding.arg)
                    {
                        case 'toggle':
                        {
                            targets.forEach(item =>
                                el.addEventListener('click', function ()
                                {
                                    item.style.display = item.offsetParent !== null ? 'none' : 'block'
                                })
                            )
                            break;
                        }
                    }
                })
            }
        }
    }
}