import MarkersOverlay from './markers-overlay'
import priceFilter from './price-filter'
import ratingFilter from './rating-filter'
import hoteltypeFilter from './hoteltype-filter'
import priceSliderStep from './price-slider-step'
import common from '~/common'
import store from './store'
import allHoteltypes from './hoteltypes'

const storeKey = 'hotels.map'

export default {
    beforeCreate()
    {
        this.$store.registerModule(storeKey.split('.'), store)
    },
    created()
    {
        this.commitAtKey('setVisible', this.startVisible)
    },
    mixins:     [common],
    props:      {
        dataSource:   {},
        cityId:       {
            type:     Number,
            required: true
        },
        initCenter:   {
            type:     Object,
            required: true
        },
        initZoom:     {
            type:     Number,
            required: true
        },
        customMedian: {
            type:     Number,
            required: true
        },
        chcatCode:    {
            type:     String,
            required: false
        },
        modalOnly:    {
            type:     Boolean,
            required: true
        },
        startVisible: {
            type:     Boolean,
            required: true
        },
        inlineWidth:  {
            type:     String,
            required: false
        },
        inlineHeight: {
            type:     String,
            required: false
        },
        storeKey:     {
            type:     String,
            required: true
        },
    },
    data()
    {
        return {
            mapDidTouchViewport:       false,
            eventModeIsOpen:           false,
            allHotels:                 [],
            allHoteltypes:             {},
            priceRange:                [],
            medianPrice:               undefined,
            filtering:                 {
                hoteltypes: [],
                priceRange: [],
                minRating:  0,
            },
            translateFilters:          {
                8:  1,      // Резорт - в Отель
                9:  0,      // Ферма - в Другое
                10: 0,      // Аренда жилья - в Другое
                13: 0,      // Room - в Другое
                5:  0,      // Мотель - в Другое
            },
            cntFilterPassed:           0,
            cntHotelsInViewportBounds: 0,
            center:                    this.initCenter,
            zoom:                      this.initZoom,
            markersOverlay:            null,
            numHotelsWithPrice:        0,
            gestureHandling:           'greedy',
            filtersPanelHeight:        0,
            mapData:                   {}
        }
    },
    mounted()
    {
        if (!this.modalOnly)
        {
            if (this.isElemIntersectsViewport(this.$el))
            {
                this.mapDidTouchViewport = true
            }
            else
            {
                let unwatchVpScroll = this.$watch('viewportScrollYWidthAndHeightCombined', (value) =>
                {
                    if (this.isElemIntersectsViewport(this.$el))
                    {
                        this.mapDidTouchViewport = true

                        unwatchVpScroll()
                    }
                })
            }
        }

        this.$store.commit('locationHash/addParamsMiddleware', ['hotelsMap', paramsObj =>
        {
            return ['map']
        }])
    },
    watch:      {
        hashStr(value, oldValue)
        {
            let hashParams = this.$store.getters['locationHash/asObject'],
                action     = this.modalOnly ? 'modalLayoutSetVisible' : 'inlineLayoutSetFullscreen',
                setVisible = hashParams.hasOwnProperty('map')

            if ((action === 'modalLayoutSetVisible') && setVisible)
            {
                this.load()
            }

            this.dispatchAtKey(action, setVisible)
        },
        isVisibleAndDidTouchViewport(value, oldValue)
        {
            if (value)
            {
                this.load()
            }
        },
        isVisibleAndFullscreen(value, oldValue)
        {
            if (value)
            {
                this.$store.dispatch('locationHash/setParamPush', 'map')

                if (this.modalOnly)
                {
                    this.reachGoal('hotels_map_opened')
                }
            }
            else
            {
                this.$store.dispatch('locationHash/deleteParamPush', 'map')

                if (this.modalOnly)
                {
                    this.reachGoal('hotels_map_closed')
                }
            }
        },
        mapDidTouchViewport()
        {
            this.load()
        },
        '$store.state.browser.width'()
        {
            this.$nextTick(() => this.filtersPanelHeight = this.$refs.filtersPanel.clientHeight)
        },
        'storeAtKey.isMapReady'()
        {
            this.$nextTick(() => this.filtersPanelHeight = this.$refs.filtersPanel.clientHeight)
        },
        isLayoutModal(value)
        {
            this.$nextTick(() => this.filtersPanelHeight = this.$refs.filtersPanel.clientHeight)

            this.$store.dispatch('browser/setScrollable', !value)
        },
        currentScreenType(value)
        {
            if (this.markersOverlay.activeMarker)
            {
                this.markersOverlay.activePopup.close()

                this.markersOverlay.activePopup.open({
                    overlay:    this.markersOverlay,
                    marker:     this.markersOverlay.activeMarker,
                    mode:       'active',
                    screenType: value
                })
            }
        },
        'markersOverlay.activeMarker'(value)
        {
            if (value)
            {
                this.markersOverlay.activePopup.close()

                this.markersOverlay.activePopup.open({
                    overlay:    this.markersOverlay,
                    marker:     value,
                    mode:       'active',
                    screenType: this.currentScreenType
                })
            }
        }
    },
    computed:   {
        viewportScrollYWidthAndHeightCombined()
        {
            return this.$store.state.browser.scrollY + '-' +
                this.$store.state.browser.width + '-' +
                this.$store.state.browser.height
        },
        isPriceFilterAvail()
        {
            return this.mapData.minPrice && this.mapData.maxPrice && (this.mapData.minPrice !== this.mapData.maxPrice)
        },
        isRatingFilterAvail()
        {
            return this.mapData.hasHotelsWithRating
        },
        isVisibleAndFullscreen()
        {
            return this.storeAtKey.isVisible && this.storeAtKey.isFullscreen
        },
        isVisibleAndDidTouchViewport()
        {
            return this.storeAtKey.isVisible && this.mapDidTouchViewport
        },
        hashStr()
        {
            return this.$store.getters['locationHash/asString']
        },
        dockPlace()
        {
            let isVisible = this.storeAtKey.isVisible

            return this.$store.state.browser.width < 480 ? 'hotels_map_on_small_screen_dock_target' : false
        },
        storeAtKey()
        {
            return this.storeKey.split('.').reduce((acc, value) => acc[value], this.$store.state)
        },
        storeNamespace()
        {
            return this.storeKey.replace('.', '/')
        },
        isLayoutFullscreenAsInline()
        {
            return (this.$store.state.browser.width < 600) || (this.$store.state.browser.height < 600)
        },
        isLayoutModal()
        {
            return this.storeAtKey && this.storeAtKey.isFullscreen && !this.isLayoutFullscreenAsInline
        },
        isLayoutInline()
        {
            return this.storeAtKey && (!this.storeAtKey.isFullscreen || this.isLayoutFullscreenAsInline)
            // return !this.isLayoutModal
        },
        currentScreenType()
        {
            if ((this.$store.state.browser.width < 600) || (this.$store.state.browser.height < 600))
            {
                return 'mobile-portrait'
            }
            else
            {
                if (this.$store.state.browser.width <= 740)
                {
                    return 'mobile-landscape'
                }
                else
                {
                    return 'desktop'
                }
            }
        },
        mapWidth()
        {
            return '100%'
        },
        mapHeight()
        {
            if (!this.storeAtKey.isVisible)
            {
                return '0'
            }

            if (this.isLayoutModal)
            {
                if (this.storeAtKey.isMapReady)
                {
                    return (this.$store.state.browser.height - this.filtersPanelHeight - 20) + 'px'
                }
                else
                {
                    return '0'
                }
            }
            else
            {
                switch (this.currentScreenType)
                {
                    case 'mobile-landscape':
                    {
                        return this.$store.state.browser.height + 'px'
                    }
                    case 'mobile-portrait':
                    {
                        let resultPx = this.$store.state.browser.height

                        if (resultPx > this.$store.state.browser.width)
                        {
                            resultPx = this.$store.state.browser.width
                        }

                        if (resultPx > 600)
                        {
                            resultPx = 600
                        }

                        return resultPx + 'px'

                        // return (this.$store.state.browser.height - this.filtersPanelHeight - 20) + 'px'
                        // return this.$store.state.browser.width + 'px'
                    }
                    case 'desktop':
                    {
                        return this.inlineHeight
                    }
                }
            }
        },
        browserDims()
        {
            return this.$store.state.browser.width + '-' + this.$store.state.browser.height
        }
    },
    methods:    {
        commitAtKey(mutation, payload)
        {
            this.$store.commit(this.storeNamespace + '/' + mutation, payload)
        },
        dispatchAtKey(action, payload)
        {
            this.$store.dispatch(this.storeNamespace + '/' + action, payload)
        },
        load()
        {
            if (this.storeAtKey.isLoadingMapData || this.storeAtKey.isMapReady)
            {
                return
            }

            this.commitAtKey('setLoadingMapData', true)

            let onTouchstart = (e) =>
            {
                window.removeEventListener('touchstart', onTouchstart)
                this.gestureHandling = 'cooperative'
            }

            window.addEventListener('touchstart', onTouchstart, {
                passive: true
            })

            $.ajax(this.dataSource, {
                dataType: 'json',
                success:  (data) =>
                          {
                              this.mapData = data

                              this.allHotels = data.hotels

                              let hoteltypes = {}
                              for (let i = 0; i < data.hoteltypes.length; i++)
                              {
                                  hoteltypes[data.hoteltypes[i]] = allHoteltypes.find(item => item.id === data.hoteltypes[i]).ru_name
                              }
                              this.allHoteltypes = hoteltypes

                              this.priceRange = [
                                  Math.floor(data.minPrice / priceSliderStep) * priceSliderStep,
                                  Math.ceil(data.maxPrice / priceSliderStep) * priceSliderStep
                              ]

                              this.medianPrice = data.medianPrice
                              /* + 20000    // USD → RUB
                                                            if (this.customMedian)
                                                            {
                                                                this.medianPrice = this.customMedian
                                                            }*/

                              if (!this.medianPrice)
                              {
                                  this.medianPrice = (data.maxPrice + data.minPrice) / 2
                              }

                              this.$refs.map.$mapPromise.then(() =>
                              {
                                  let minLat = 90,
                                      maxLat = -90,
                                      minLng = 180,
                                      maxLng = -180

                                  this.allHotels.forEach(item =>
                                  {
                                      item.latLng = new google.maps.LatLng(item.lat, item.lng)
                                      if (item.lat < minLat)
                                      {
                                          minLat = item.lat
                                      }
                                      if (item.lat > maxLat)
                                      {
                                          maxLat = item.lat
                                      }
                                      if (item.lng < minLng)
                                      {
                                          minLng = item.lng
                                      }
                                      if (item.lng > maxLng)
                                      {
                                          maxLng = item.lng
                                      }
                                      if (item.price > 0)
                                      {
                                          this.numHotelsWithPrice++
                                      }
                                  })

                                  let cityHotelsBounds = new google.maps.LatLngBounds(
                                      new google.maps.LatLng(maxLat, minLng),
                                      new google.maps.LatLng(minLat, maxLng)
                                  )

                                  setTimeout(() =>
                                  {
                                      this.$refs.map.$mapObject.fitBounds(cityHotelsBounds)
                                  })

                                  this.filterHotels()

                                  this.markersOverlay = new MarkersOverlay({
                                      component:                           this,
                                      map:                                 this.$refs.map.$mapObject,
                                      markers:                             this.allHotels,
                                      storageKey:                          this.cityId,
                                      onNumMarkersInViewportBoundsChanged: () =>
                                                                           {
                                                                               this.cntHotelsInViewportBounds = this.markersOverlay.numMarkersInViewportBounds
                                                                           }
                                  })

                                  this.commitAtKey('setMapReady', true)

                                  this.commitAtKey('setLoadingMapData', false)
                              })
                          }
            })

            this.$nextTick(() =>
            {
                let vm = this

                this.$refs.map.$mapPromise.then(() =>
                {
                    google.maps.event.addListener(this.$refs.map.$mapObject, 'zoom_changed', function ()
                    {
                        vm.zoom = this.zoom
                    })

                    google.maps.event.addListener(this.$refs.map.$mapObject, 'dragend', function ()
                    {
                        vm.center = {lat: this.center.lat(), lng: this.center.lng()}
                        // localStorage.setItem('hotelsOnMap.center[' + vm.cityId + ']', JSON.stringify(vm.center)
                    })
                })
            })
        },
        draw()
        {
            this.markersOverlay.redraw()
        },
        filterHotels()
        {
            this.allHotels.forEach(item => item.filterPassed = true)

            let activeHoteltypeFilters = this.filtering.hoteltypes.filter(item => item)

            if (activeHoteltypeFilters.length)
            {
                this.allHotels.forEach(item =>
                {
                    item.filterPassed = item.filterPassed &&
                        (this.filtering.hoteltypes[item.proptype] ||
                            (
                                this.translateFilters.hasOwnProperty(item.proptype) &&
                                this.filtering.hoteltypes[this.translateFilters[item.proptype]]
                            )
                        )
                })
            }

            if (this.filtering.priceRange.length === 2)
            {
                if (
                    this.filtering.priceRange[0] !== this.priceRange[0] ||
                    this.filtering.priceRange[1] !== this.priceRange[1]
                )
                {
                    this.allHotels.forEach(item =>
                    {
                        item.filterPassed = item.filterPassed &&
                            (
                                (item.price >= this.filtering.priceRange[0]) &&
                                (item.price <= this.filtering.priceRange[1])
                            )
                    })
                }
            }

            this.allHotels.forEach(item =>
            {
                item.filterPassed = item.filterPassed && ((item.rating / 10) >= this.filtering.minRating)
            })

            this.cntFilterPassed = 0
            this.allHotels.forEach(item => item.filterPassed && this.cntFilterPassed++)
        },
        applyFilter(value, name)
        {
            this.filtering[name] = value
            this.filterHotels()
            this.markersOverlay && this.markersOverlay.draw()
        },
        onHoteltypeFilterChange(value)
        {
            this.applyFilter(value, 'hoteltypes')
        },
        onPriceFilterChange(value)
        {
            this.applyFilter(value, 'priceRange')
        },
        onRatingFilterChange(value)
        {
            this.applyFilter(value, 'minRating')
        },
    },
    components: {
        priceFilter,
        hoteltypeFilter,
        ratingFilter,
    }
}
