export default {

    data() {
        return {
            isAgree: false,
            isValid: false
        }
    },
    methods: {
        onToggleAgree(event) {
            this.isValid = this.isAgree
        }
    }
}
