import common from '~/common';

export default {
    mixins:   [common],
    methods:  {
        setActive(layout)
        {
            this.reachGoal('hotels_set_layout_' + layout)

            this.$store.dispatch('hotels/gridlist/setItemsLayout', layout)
        },
    },
    computed: {
        isWideActive()
        {
            return this.$store.state.hotels.gridlist.itemsLayout === 'wide'
        },
        isGridActive()
        {
            return this.$store.state.hotels.gridlist.itemsLayout === 'grid'
        },
    },
}
