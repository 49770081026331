export default guessCurrentScreenType

function guessCurrentScreenType() {

    let wndInnerWidth  = window.innerWidth,
        wndInnerHeight = window.innerHeight

    if (wndInnerWidth < wndInnerHeight) {
        return 'mobile-portrait'
    } else {
        if (wndInnerWidth <= 740) {
            return 'mobile-landscape'
        } else {
            return 'desktop'
        }
    }
}
