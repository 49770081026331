import Vue from 'vue'
import {parseHash, stringifyHash, getCurrentHash, setCurrentHash} from './util'

window.addEventListener('hashchange', () =>
{
    window.vueApp.$store.dispatch('locationHash/setFromRawStringReplace', getCurrentHash())
})

document.addEventListener('DOMContentLoaded', () =>
{
    window.vueApp.$store.dispatch('locationHash/setFromRawStringReplace', getCurrentHash())
})

export default {
    namespaced: true,
    state:      {
        asUnfilteredObject: parseHash(getCurrentHash()),
        paramsMiddleware:   {},
    },
    getters:    {
        asUnfilteredString: state =>
                            {
                                return stringifyHash(state.asUnfilteredObject)
                            },
        asString:           (state, getters) =>
                            {
                                return stringifyHash(getters.asObject)
                            },
        asObject:           (state, getters) =>
                            {
                                let result         = parseHash(getters.asUnfilteredString),
                                    middlewareCallback,
                                    allowParamKeys = []

                                for (let middlewareKey in state.paramsMiddleware)
                                {
                                    if (!state.paramsMiddleware.hasOwnProperty(middlewareKey))
                                    {
                                        continue
                                    }

                                    middlewareCallback = state.paramsMiddleware[middlewareKey]

                                    allowParamKeys = allowParamKeys.concat(
                                        middlewareCallback(result) || []
                                    )
                                }

                                for (let paramKey in result)
                                {
                                    if (!result.hasOwnProperty(paramKey))
                                    {
                                        continue
                                    }

                                    if (!allowParamKeys.includes(paramKey))
                                    {
                                        delete result[paramKey]
                                    }
                                }

                                return result
                            }
    },
    mutations:  {
        setFromRawString(state, hashStr)
        {
            state.asUnfilteredObject = parseHash(hashStr)
        },
        setParam(state, [key, value])
        {
            Vue.set(state.asUnfilteredObject, key, value)
        },
        deleteParam(state, key)
        {
            Vue.delete(state.asUnfilteredObject, key)
        },
        addParamsMiddleware(state, [middlewareKey, middlewareCallback])
        {
            Vue.set(state.paramsMiddleware, middlewareKey, middlewareCallback)
        },
        deleteParamsMiddleware(state, middlewareKey)
        {
            Vue.delete(state.paramsMiddleware, middlewareKey)
        },
    },
    actions:    {
        setFromRawStringPush(store, payload)
        {
            store.commit('setFromRawString', payload)

            setCurrentHash(store.getters.asString, true)
        },
        setParamPush(store, payload)
        {
            if (typeof payload === 'string')
            {
                payload = [payload, '']
            }

            store.commit('setParam', payload)

            setCurrentHash(store.getters.asString, true)
        },
        deleteParamPush(store, payload)
        {
            store.commit('deleteParam', payload)

            setCurrentHash(store.getters.asString, true)
        },
        setFromRawStringReplace(store, payload)
        {
            store.commit('setFromRawString', payload)

            setCurrentHash(store.getters.asString, false)
        },
        setParamReplace(store, payload)
        {
            if (typeof payload === 'string')
            {
                payload = [payload, '']
            }

            store.commit('setParam', payload)

            setCurrentHash(store.getters.asString, false)
        },
        deleteParamReplace(store, payload)
        {
            store.commit('deleteParam', payload)

            setCurrentHash(store.getters.asString, false)
        },
        addParamsMiddleware(store, payload)
        {
            store.commit('addParamsMiddleware', payload)

            setCurrentHash(store.getters.asString, false)
        },
        deleteParamsMiddleware(store, payload)
        {
            store.commit('deleteParamsMiddleware', payload)

            setCurrentHash(store.getters.asString, false)
        },
    }
}
