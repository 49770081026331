import sortingOption from './sorting-option'

export default {
    components: {sortingOption},
    props:      {
        hgls:    {
            type:     Object,
            required: true
        },
        sorting: {
            type:     Object,
            required: true
        }
    },
    data()
    {
        return {
            typeKey:   null,
            direction: null
        }
    },
    mounted()
    {
        this.hgls.onLoadAppendData.push(data =>
        {
            data.sorting = this.sorting
        })
    },
    computed:   {
        dropdownValue: {
            get()
            {
                return this.sortingToDropdownValueStr(this.sorting)
            },
            set(value)
            {
                let sorting = this.dropdownValueStrToSorting(value)

                this.$emit('change', sorting)
            }
        }
    },
    methods:    {
        sortingToDropdownValueStr(sorting)
        {
            if (!sorting.typeKey)
            {
                return 'na'
            }
            else
            {
                return sorting.typeKey + (sorting.direction ? ',' + sorting.direction : '')
            }
        },
        dropdownValueStrToSorting(value)
        {
            value = value.split(',')

            let result = {typeKey: value[0]}

            if (value[1])
            {
                result.direction = value[1]
            }

            return result
        },
        onButtonClick(typeKey, defaultDirection)
        {
            let sorting = {typeKey}

            if (this.sorting.typeKey === typeKey)
            {
                if (defaultDirection)
                {
                    sorting.direction = this.sorting.direction === 'asc' ? 'desc' : 'asc'
                }
            }
            else
            {
                if (defaultDirection)
                {
                    sorting.direction = defaultDirection
                }
            }

            this.$emit('change', sorting)
        },
        onDropdownChange()
        {
            let value   = this.$refs.sortingDropdown.value,
                sorting = this.dropdownValueStrToSorting(value)

            this.$emit('change', sorting)
        }
    }
}
