export default {
    data()
    {
        return {
            sliderOptions: {
                min:      0,
                max:      10,
                dotSize:  32,
                realTime: true,
                lazy:     false,
                tooltip:  false,
                interval: 0.5,
                reverse:  true,
            },
            value:         10
        }
    },
    methods:  {
        onValueChanged()
        {
            if (this.transformedValue > 9.5)
            {
                this.$refs.slider.setValue(0.5, true)
            }

            this.$emit('value-change', this.transformedValue)
        }
    },
    computed: {
        transformedValue()
        {
            return 10 - this.value
        }
    },
    watch:    {
        '$parent.storeAtKey.isFullscreen'()
        {
            this.$refs.slider.refresh()
        },
    },
}
