import velocity from 'velocity-animate'

export default{
    data(){
        return {
            scrollY: window.scrollY
        }
    },
    methods: {
        handleScroll () {
            this.scrollY = window.scrollY;
        },
        scrollTop(){
            velocity(document.getElementsByTagName('html'), 'scroll', {offset: '0px', duration: 200});
        }
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
