export default class Amenities {
    constructor() {
        this.items = getItems()
        this.items.forEach(item => item.checked = false)
    }

    reset(){
        this.items.forEach(item => item.checked = false)
    }
}

function getItems() {
    return [
        {
            htlkName: 'Ресепшн 24 часа',
            htlkKey:  'hotel:24_hours_front_desk_service',
            bcName:   '',
            bcKey:    null
        },
        // {
        //     htlkName: 'Анимация',
        //     htlkKey: 'hotel:animation',
        //     bcName: '',
        //     bcKey: null
        // },
        // {
        //     htlkName: 'Няня',
        //     htlkKey: 'hotel:babysitting',
        //     bcName: '',
        //     bcKey: null
        // },
        {
            htlkName: 'Бар',
            htlkKey:  'hotel:bar',
            bcName:   '',
            bcKey:    null
        },
        {
            htlkName: 'Бизнес-центр',
            htlkKey:  'hotel:business_centre',
            bcName:   '',
            bcKey:    null
        },
        // {
        //     htlkName: 'Развлечения для детей',
        //     htlkKey: 'hotel:children_care_activities',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Консьерж',
        //     htlkKey: 'hotel:concierge_service',
        //     bcName: '',
        //     bcKey: ''
        // },
        {
            htlkName: 'Депозит',
            htlkKey:  'hotel:deposit',
            bcName:   '',
            bcKey:    null
        },
        // {
        //     htlkName: 'Gay friendly',
        //     htlkKey: 'hotel:gay_friendly',
        //     bcName: '',
        //     bcKey: ''
        // },
        {
            htlkName: 'Спортзал',
            htlkKey:  'hotel:gym',
            bcName:   'Фитнес-центр',
            bcKey:    '11'
        },
        {
            htlkName: 'Прачечная',
            htlkKey:  'hotel:laundry_service',
            bcName:   '',
            bcKey:    null
        },
        // {
        //     htlkName: 'Гей-френдли',
        //     htlkKey: 'hotel:lgbt_friendly',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Помощь малоподвижным людям',
        //     htlkKey: 'hotel:low_mobility_guests_welcome',
        //     bcName: 'Удобства для гостей с ограниченными физическими возможностями',
        //     bcKey: '25'
        // },
        {
            htlkName: 'Мед. услуги',
            htlkKey:  'hotel:medical_service',
            bcName:   '',
            bcKey:    null
        },
        {
            htlkName: 'Парковка',
            htlkKey:  'hotel:parking',
            bcName:   'Парковка',
            bcKey:    '2'
        },
        {
            htlkName: 'С животными',
            htlkKey:  'hotel:pets_allowed',
            bcName:   'Допускается размещение домашних животных',
            bcKey:    '4'
        },
        {
            htlkName: 'Ресторан/кафе',
            htlkKey:  'hotel:restaurant_cafe',
            bcName:   'Ресторан',
            bcKey:    '3'
        },
        {
            htlkName: 'Спа',
            htlkKey:  'hotel:spa',
            bcName:   'Спа и оздоровительный центр',
            bcKey:    '54'
        },
        {
            htlkName: 'Бассейн',
            htlkKey:  'hotel:swimming_pool',
            bcName:   'Бассейн',
            bcKey:    '301'
        },
        {
            htlkName: 'Wi-Fi в лобби',
            htlkKey:  'hotel:wi-fi_in_public_areas',
            bcName:   '',
            bcKey:    null
        },
        // {
        //     htlkName: null,
        //     htlkKey: null,
        //     bcName: 'Бесплатный Wi-Fi',
        //     bcKey: '107'
        // },
        // {
        //     htlkName: 'Кондиционер',
        //     htlkKey: 'room:air_conditioning',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Халаты',
        //     htlkKey: 'room:bathrobes',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Ванна',
        //     htlkKey: 'room:bathtub',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Уборка',
        //     htlkKey: 'room:cleaning',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Чай/Кофе',
        //     htlkKey: 'room:coffee_tea',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Смежные номера',
        //     htlkKey: 'room:connecting_rooms',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Ежедневная уборка',
        //     htlkKey: 'room:daily_housekeeping',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Фен',
        //     htlkKey: 'room:hairdryer',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Мини-бар',
        //     htlkKey: 'room:mini_bar',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Ванная комната',
        //     htlkKey: 'room:private_bathroom',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Сейф',
        //     htlkKey: 'room:safe_box',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Общая ванная',
        //     htlkKey: 'room:shared_bathroom',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Душ',
        //     htlkKey: 'room:shower',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Курящие номера',
        //     htlkKey: 'room:smoking_room',
        //     bcName: '',
        //     bcKey: ''
        // },
        // {
        //     htlkName: 'Телевизор',
        //     htlkKey: 'room:tv',
        //     bcName: '',
        //     bcKey: ''
        // },
    ]
}