export default {

    getLocationHash()
    {
        return (window.location.hash || '').replace(/^#/, '')
    },

    setLocationHash(hashStr)
    {
        let base = location.origin + location.pathname

        if (hashStr.length)
        {
            history.pushState(null, null, base + '#' + hashStr)
        }
        else
        {
            history.pushState(null, null, base)
        }
    },

    plnum(wordForms, number)
    {
        if (((number % 100) > 4) && ((number % 100) < 20))
        {
            return wordForms[2];
        }
        else
        {
            switch (number % 10)
            {
                case 0:
                    return wordForms[2];
                case 1:
                    return wordForms[0];
                case 2:
                case 3:
                case 4:
                    return wordForms[1];
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                default:
                    return wordForms[2];
            }
        }
    },

}