import filtering from './../hglsfiltering/store'
import sorting from './../hglssorting/store'
import hashParams from './../hglshashparams/store'

import utils from '~/utils'

function setArrayHasId(idsArray, id, boolValue)
{
    let index = idsArray.indexOf(id)

    if (boolValue && (index === -1))
    {
        idsArray.push(id)
    }

    if (!boolValue && (index !== -1))
    {
        idsArray.splice(index, 1)
    }
}

export default {
    namespaced: true,
    modules:    {filtering, sorting, hashParams},
    state:      {
        itemsLayout:              'wide',
        requestsStarted:          [],
        activeSliderHotelIds:     [],
        loadedFirstImageHotelIds: [],
        cntHotelsFound:           null,
        cntPagesDisplayed:        1,
    },
    mutations:  {
        setCntHotelsFound(state, cntHotels)
        {
            state.cntHotelsFound = cntHotels
        },
        setCntPagesDisplayed(state, cntPages)
        {
            state.cntPagesDisplayed = cntPages
        },
        setHotelFirstImageLoaded(state, {hotelId, isActive})
        {
            setArrayHasId(state.loadedFirstImageHotelIds, hotelId, isActive)
        },
        setItemsLayout(state, layout)
        {
            state.itemsLayout = layout
        },
        requestStarted(state, data)
        {
            state.requestsStarted.push(data)
        },
        setHotelSliderActive(state, {hotelId, isActive})
        {
            setArrayHasId(state.activeSliderHotelIds, hotelId, isActive)
        },
        clearAllHotelSliderActive(state)
        {
            state.activeSliderHotelIds = []
        },
    },
    actions:    {
        setHotelFirstImageLoaded(context, payload)
        {
            setTimeout(function ()
            {
                context.commit('setHotelFirstImageLoaded', payload)
            }, 100)
        },
        setItemsLayout(context, layout)
        {
            context.commit('setItemsLayout', layout)

            let savedIds = context.state.activeSliderHotelIds

            context.commit('clearAllHotelSliderActive')

            setTimeout(() =>
            {
                for (let i = 0; i < savedIds.length; i++)
                {
                    context.commit('setHotelSliderActive', {hotelId: savedIds[i], isActive: true})
                }
            }, 1)
        },
    },
    getters:    {
        showmoreVariantsLabel(state)
        {
            let cntHotelsRemaining = state.cntHotelsFound - (state.cntPagesDisplayed * 12)

            return 'Показать ещё ' + cntHotelsRemaining + ' ' + utils.plnum(['вариант', 'варианта', 'вариантов'], cntHotelsRemaining)
        },
        itemsActualLayout(state, getters, rootState)
        {
            return (rootState.browser.width < 600) ? 'small-screen' : state.itemsLayout
        },
        itemsLayoutClass(state, getters)
        {
            return 'hgls-items-layout-' + getters.itemsActualLayout
        },
        itemWrapperElemHeight(state, getters, rootState)
        {
            switch (getters.itemsActualLayout)
            {
                case 'small-screen':
                    return 'auto'
                case 'wide':
                    return 'auto'   // Seems redundant
                case 'grid':
                    return 'auto'
            }

            return 'auto'
        },
        itemInnerElemHeight(state, getters, rootState)
        {
            switch (getters.itemsActualLayout)
            {
                case 'small-screen':
                    return 'auto'
                case 'wide':
                    return 'auto'   // Seems redundant
                case 'grid':
                    return '100%'
            }

            return 'auto'
        },
    }
}
