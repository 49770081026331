import _ from 'lodash';

window.addEventListener('resize', _.throttle(updateViewportDims.bind(this), 100))

window.addEventListener('orientationchange', _.throttle(updateViewportDims.bind(this), 100))

window.addEventListener('scroll', _.throttle(updateViewportScroll.bind(this), 100), {
    capture: true,
    passive: true
})

window.addEventListener('touchstart', onTouchstart, {
    passive: true
})

function onTouchstart()
{
    window.vueApp.$store.commit('browser/touchWasDetected')

    window.removeEventListener('touchstart', onTouchstart)

    document.body.classList.add('has-touch-support')
}

function updateViewportDims()
{
    let store = window.vueApp.$store

    if (store.state.browser.width !== window.innerWidth)
    {
        store.commit('browser/widthChanged', window.innerWidth)
    }

    if (store.state.browser.height !== window.innerHeight)
    {
        store.commit('browser/heightChanged', window.innerHeight)
    }
}

function updateViewportScroll()
{
    let store = window.vueApp.$store

    if (store.state.browser.scrollX !== window.scrollX)
    {
        store.commit('browser/scrollXChanged', window.scrollX)
    }

    if (store.state.browser.scrollY !== window.scrollY)
    {
        store.commit('browser/scrollYChanged', window.scrollY)
    }
}
