<!-- https://github.com/JosephusPaye/Keen-UI/blob/master/src/UiAutocomplete.vue -->
<!-- http://taha-sh.com/blog/building-an-awesome-reusable-autocomplete-input-component-in-vue-21-part-one -->
<template>
    <div class="nt-autocomplete" :class="classes">
        <div class="nt-autocomplete__input-wrapper">
            <slot name="input">
                <input :id="inputElemId"
                       type="text"
                       v-model="search"
                       :placeholder="placeholder"
                       class="nt-autocomplete__input"
                       ref="input"
                       @focus="isFocused = true"
                       @blur="isFocused = false"
                       @input="onInput($event.target.value)"
                       @keydown.down="kbDown"
                       @keydown.up="kbUp"
                       @keydown.enter.prevent="kbEnter"
                       @keydown.esc="kbEsc"
                />
            </slot>
        </div>
        <div v-show="isOpen" class="nt-autocomplete__suggestions">
            <slot name="suggestions">
                <ul v-if="isGrouped"
                    class="nt-autocomplete__groups">
                    <li v-for="(group, groupIndex) in suggestions">
                        <slot name="group-title" :group="group" :group-index="groupIndex">
                            <div class="nt-autocomplete__group-label"
                                 v-text="group[groupLabelKey]"></div>
                        </slot>
                        <ul class="nt-autocomplete__group-items">
                            <li class="nt-autocomplete__group-item"
                                v-for="(item, itemIndex) in group[groupItemsKey]"
                                :class="{
                                 'kb-focused': (groupIndex === kbGroupIndex) && (itemIndex === kbItemIndex)
                             }"
                            >
                                <a class="nt-autocomplete__group-item-a"
                                   v-text="item[itemLabelKey]"
                                   @mousedown="onSelectItem(item)"></a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul v-else
                    class="nt-autocomplete__items">
                    <li v-for="item in suggestions">
                        <p>&nbsp;</p>
                    </li>
                </ul>
            </slot>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        props: {
            inputElemId: {
                type: String,
                required: true
            },
            getDataFn: {
                type: Function,
                required: true
            },
            initSuggestions: {
                type: Array,
                default()
                {
                    return []
                }
            },
            initSearch: {
                type: String,
                default()
                {
                    return ''
                }
            },
            itemLabelKey: {
                type: String,
                default: 'label'
            },
            groupLabelKey: {
                type: String,
                default: 'label'
            },
            groupItemsKey: {
                type: String,
                default: 'items'
            },
            isGrouped: {
                type: Boolean
            },
            placeholder: {
                type: String,
                default: ''
            },
            debounceInterval: {
                type: Number
            }
        },
        data()
        {
            return {
                isFocused: false,
                kbGroupIndex: 0,
                kbItemIndex: 0,
                suggestions: this.initSuggestions,
                search: this.initSearch,
            }
        },
        computed: {
            classes()
            {
                return {
                    'is-focused': this.isFocused,
                    'is-open': this.isOpen
                }
            },
            isOpen()
            {
                return this.isFocused && this.suggestions.length
            },
            inputDebounceFn()
            {
                let vm = this
                return debounce(function (value) {
                    vm.getDataFn(value, function (result) {
                        vm.suggestions = result
                    })
                }, this.debounceInterval)
            }
        },
        watch: {},
        methods: {
            onInput(value)
            {
                this.$emit('type', value)
                this.$emit('change', null)
                this.inputDebounceFn(value)
            },
            onSelectItem(item)
            {
                this.$nextTick(() => {
                    this.$emit('change', item)
                    this.search = item[this.itemLabelKey]
                })
            },
            kbDown()
            {
                if (this.isGrouped)
                {
                    let group = this.suggestions[this.kbGroupIndex];
                    this.kbItemIndex++;
                    if (this.kbItemIndex >= group[this.groupItemsKey].length)
                    {
                        this.kbItemIndex = 0;
                        this.kbGroupIndex++;
                        if (this.kbGroupIndex >= this.suggestions.length)
                        {
                            this.kbGroupIndex = 0;
                        }
                    }
                }
                else
                {
                }
            },
            kbUp()
            {
                if (this.isGrouped)
                {
                    this.kbItemIndex--;
                    if (this.kbItemIndex < 0)
                    {
                        this.kbGroupIndex--;
                        if (this.kbGroupIndex < 0)
                        {
                            this.kbGroupIndex = this.suggestions.length - 1;
                        }
                        let group = this.suggestions[this.kbGroupIndex];
                        this.kbItemIndex = group[this.groupItemsKey].length - 1;
                    }
                }
                else
                {
                }
            },
            kbEnter()
            {
                let group = this.suggestions[this.kbGroupIndex],
                    item = group[this.groupItemsKey][this.kbItemIndex];
                this.search = item[this.itemLabelKey];
                this.$emit('change', item);
            },
            kbEsc()
            {
                this.$refs.input.blur()
            }
        }
    }
</script>
