window.setCookie = (name, value, days = 7, path = '/') =>
{
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

window.getCookie = (name) =>
{
    return document.cookie.split('; ').reduce((r, v) =>
    {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}

window.deleteCookie = (name, path) =>
{
    setCookie(name, '', -1, path)
}

import Moment from 'moment'

Moment.locale('ru')
// import jq from 'jquery'
// window.$ = window.jQuery = jq
window.$ = window.jQuery = require('jquery')
import './jquery-ui'
import Vue from 'vue'
import vueSlider from 'vue-slider-component'
import Slick from './partials/common/vue-slick/slickCarousel'
import ntfooter from './../../plugins/jdmpnn/shared/components/footer'
import scrollTopButton from './partials/common/scroll-top-button'
import hotelsSearchForm from './partials/hotels/search-form'
// import hotelPage from './partials/hotels/page-hotel'
import partnersCarousel from './partials/hotels/page-home/partners-carousel/index'
import citiesListSortTabs from './partials/hotels/page-country/cities-list-sort-tabs'
import {directive as onClickaway} from 'vue-clickaway'
import NtAutocomplete from './partials/common/js/nt-autocomplete.vue'
import NtCheckbox from './partials/common/js/nt-checkbox.vue'
import datepickerRu from './partials/common/js/datepicker-ru'
import collapsible from './partials/common/collapsible'
import common from './common'
import * as VueGoogleMaps from 'vue2-google-maps'
import vueRuntimeTemplate from './partials/vue-runtime-template'
import hotels from './../../plugins/jdmpnn/hotels/components/hotels/index'
import hotelsGridlist from './../../plugins/jdmpnn/hotels/components/hotelsgridlist/index'
import hglsSetLayoutButtons from './../../plugins/jdmpnn/hotels/components/hotelsgridlist/set-layout-buttons'
import hglsHashParams from './../../plugins/jdmpnn/hotels/components/hglshashparams/index'
import hglsFiltering from './../../plugins/jdmpnn/hotels/components/hglsfiltering/index'
import hglsSorting from './../../plugins/jdmpnn/hotels/components/hglssorting/index'
import hglsPage from './../../plugins/jdmpnn/hotels/components/hglspage/index'
import hglsHotel from './../../plugins/jdmpnn/hotels/components/hglspage/hotel'
import hotelsMap from './../../plugins/jdmpnn/hotels/components/hotelsmap/index'
import MobileDetect from 'mobile-detect/mobile-detect'
import testimonials from './dbpages/nt/staticpage-page_template/otzyvy'
import utm from './partials/hotels/utm'
import fhelper from './partials/fhelper/index'

import vueGlobalMixin from './vue-global-mixin'

import testLocationHash from './js/location-hash/test'

import hotelsGlobalMixin from '#/hotels/global-mixin'

import './js/index'

import store from './store'

let md = new MobileDetect(window.navigator.userAgent);
if (md.mobile())
{
    document.body.classList.add('is-mobile');
}

Vue.use(VueGoogleMaps, {
    load: {
        key:       'AIzaSyAwNn8zjgXK_utKHfblDbOhIpINHDKfrxU',
        libraries: 'places'
    }
})

$.datepicker.regional.ru = datepickerRu
$.datepicker.setDefaults($.datepicker.regional['ru'])
$.fx.off = true

Vue.directive('on-clickaway', onClickaway)

Vue.component('fhelper', fhelper)
Vue.component('nt-autocomplete', NtAutocomplete)
Vue.component('nt-checkbox', NtCheckbox)
Vue.component('slick', Slick)
Vue.component('collapsible', collapsible)
Vue.component('vue-slider', vueSlider)
Vue.component('ntfooter', ntfooter)
Vue.component('scrollTopButton', scrollTopButton)
Vue.component('hotelsSearchForm', hotelsSearchForm)
Vue.component('partnersCarousel', partnersCarousel)
Vue.component('citiesListSortTabs', citiesListSortTabs)
Vue.component('vueRuntimeTemplate', vueRuntimeTemplate)
Vue.component('hotels', hotels)
// Vue.component('hotelPage', hotelPage)
Vue.component('hotelsMap', hotelsMap)
Vue.component('hotelsGridlist', hotelsGridlist)
Vue.component('hglsSetLayoutButtons', hglsSetLayoutButtons)
Vue.component('hglsHashParams', hglsHashParams)
Vue.component('hglsFiltering', hglsFiltering)
Vue.component('hglsSorting', hglsSorting)
Vue.component('hglsPage', hglsPage)
Vue.component('hglsHotel', hglsHotel)
Vue.component('testimonials', testimonials)

Vue.component('testLocationHash', testLocationHash)

let globalMixins = [common]

if (window.hotels)
{
    globalMixins.push(hotelsGlobalMixin)
}

Vue.mixin(vueGlobalMixin)

window.vueApp = new Vue({
    el:      '#app',
    store,
    mixins:  globalMixins,
    data()
    {
        return {
            adminPanelData: {},
        }
    },
    methods: {
        range: function (start, end)
               {
                   return Array(end - start + 1).fill().map((_, idx) => start + idx)
               },
        toggleAdminPanelData(data, idVariable)
        {
            if (
                (Object.entries(this.adminPanelData).length === 0) ||
                (this.adminPanelData[idVariable] !== data[idVariable])
            )
            {
                this.adminPanelData = data
            }
            else
            {
                this.adminPanelData = {}
            }
        },
    },
    mounted()
    {
        utm.loadUtm()
    },
})
