import Vue from 'vue'
import velocity from 'velocity-animate'
import allHoteltypes from './hoteltypes'
import userAgent from '~/useragent-adapter'

let allHoteltypesById = {}

for (let i = 0; i < allHoteltypes.length; i++)
{
    allHoteltypesById[allHoteltypes[i].id] = allHoteltypes[i]
}

function getPatchedItemLink(itemLink)
{
    if ((userAgent.device === 'mobile') && (['yandex', 'firefox'].includes(userAgent.vendor)))
    {
        itemLink = itemLink.replace('://booking.com', '://m.booking.com')
    }

    return itemLink
}

export default MarkerPopup

function MarkerPopup()
{
}

MarkerPopup.firstClickScrollFlag = true

MarkerPopup.prototype.open = function (options)
{
    this.close()

    for (let prop in options)
    {
        if (options.hasOwnProperty(prop))
        {
            this[prop] = options[prop]
        }
    }

    this.popupContainer = document.createElement('div')

    this.useScreenType = undefined

    switch (this.mode)
    {
        case 'hover':

            this.popupContainer.className = 'hotels-on-map-popup-hover-desktop'
            this.popupContainer.innerHTML = hoverPopupTemplateFn(this.marker)
            this.overlay.map.getDiv().appendChild(this.popupContainer)

            let projection = this.overlay.getProjection(),
                markerPos  = projection.fromLatLngToContainerPixel(new google.maps.LatLng(this.marker.lat, this.marker.lng)),
                popupW     = this.popupContainer.offsetWidth,
                popupH     = this.popupContainer.offsetHeight,
                viewportW  = this.overlay.map.getDiv().offsetWidth,
                viewportH  = this.overlay.map.getDiv().offsetHeight,
                popupTop,
                popupLeft

            popupTop = markerPos.y - popupH - 30
            if (popupTop < 15)
            {
                popupTop = 15
            }
            this.popupContainer.style.top = popupTop + 'px'

            popupLeft = markerPos.x + 10
            if (popupLeft + popupW > viewportW - 15)
            {
                popupLeft = markerPos.x - popupW - 10
            }
            this.popupContainer.style.left = popupLeft + 'px'

            break

        case 'active':

            this.place()

            break;
    }
}

MarkerPopup.prototype.place = function ()
{
    if (!this.popupContainer)
    {
        return
    }

    if (this.screenType === this.useScreenType)
    {
        return
    }

    this.useScreenType = this.screenType

    let placeFn = ('place-' + this.useScreenType).replace(/(-\w)/g, function (m)
    {
        return m[1].toUpperCase();
    })

    this[placeFn]()
}

MarkerPopup.prototype.placeDesktop = function ()
{
    this.detach()

    this.popupContainer.className = 'hotels-on-map-popup-active-desktop'
    this.overlay.map.getDiv().appendChild(this.popupContainer)

    let self = this

    let popupComponent = new Vue({
        parent:   this.overlay.component,
        template: activePopupDesktopTemplateFn(this.marker),
        methods:  {
            close()
            {
                self.overlay.deactivateCurrentMarker()
                self.close()
            }
        }
    }).$mount(this.popupContainer, true)
}

MarkerPopup.prototype.placeMobilePortrait = function ()
{
    this.detach()

    this.popupContainer.className = 'hotels-on-map-popup-active-mobile-portrait'
    document.getElementById('marker_popup_outer').appendChild(this.popupContainer)

    let self = this

    let popupComponent = new Vue({
        parent:   this.overlay.component,
        template: activePopupMobilePortraitTemplateFn(this.marker),
        methods:  {
            close()
            {
                self.overlay.deactivateCurrentMarker()
                self.close()
            }
        }
    }).$mount(this.popupContainer, true)

    if (MarkerPopup.firstClickScrollFlag)
    {
        velocity(document.getElementById('hotels_on_map_root'), 'scroll', {offset: '0px', duration: 200});
        MarkerPopup.firstClickScrollFlag = false
    }
}

MarkerPopup.prototype.placeMobileLandscape = function ()
{
    this.detach()

    this.popupContainer.className = 'hotels-on-map-popup-active-mobile-landscape'
    this.overlay.map.getDiv().appendChild(this.popupContainer)

    let self = this

    let popupComponent = new Vue({
        parent:   this.overlay.component,
        template: activePopupMobileLandscapeTemplateFn(this.marker),
        methods:  {
            close()
            {
                self.overlay.deactivateCurrentMarker()
                self.close()
            }
        }
    }).$mount(this.popupContainer, true)
}

MarkerPopup.prototype.close = function ()
{
    this.detach()
    this.reset()
}

MarkerPopup.prototype.detach = function ()
{
    if (this.popupContainer && this.popupContainer.parentNode)
    {
        this.popupContainer.parentNode.removeChild(this.popupContainer)
    }
}

MarkerPopup.prototype.reset = function ()
{
    for (let prop in this)
    {
        if (this.hasOwnProperty(prop))
        {
            delete this[prop]
        }
    }
}

function hoverPopupTemplateFn(item)
{
    return `
        <div>
            <img class="image-elem" src="https://photo.hotellook.com/image_v2/limit/h${item.id}_0/110/110.auto"/>
            <div class="details-col">
                <div class="prop-name-and-stars">
                    <span class="prop-name">${item.title}</span>
                    <span class="prop-stars">${new Array(parseInt(item.stars) + 1).join('★')}</span>
                </div>
                ${item.rating > 0 ? `
                    <div class="prop-type-and-rating"
                        >${item.proptype !== 0 ? allHoteltypesById[item.proptype].ru_name : 'Рейтинг'}
                        <span class="prop-rating">${item.rating / 10}</span></div>
                ` : `
                    ${item.proptype !== 0 ? `
                        <div class="prop-type-and-rating">${allHoteltypesById[item.proptype].ru_name}</div>` : ''} 
                `}
                ${item.price > 0 ? `
                    <div class="prop-price">От ${item.price} р. в сутки</div>` : ''}
            </div>
        </div>
        `
}

function activePopupDesktopTemplateFn(item)
{
    let itemLink = getPatchedItemLink(item.link)

    return `
        <div>
            <div class="image-wrapper">
                <img src="https://photo.hotellook.com/image_v2/limit/h${item.id}_0/240/180.auto"
                     class="image-elem" onclick="window.open('${itemLink}')"/>
                <div class="popup-close"
                    @click="close()"
                >
                    <div class="close-iconelem" style="transform: translate(8px, 14px) rotate(45deg);"></div>                
                    <div class="close-iconelem" style="transform: translate(8px, 14px) rotate(-45deg);"></div>                
                </div>
            </div>
            <div class="prop-name-and-stars">
                <a target="_blank" href="${itemLink}" class="prop-name">${item.title}</a>
                <span class="prop-stars">
                    ${new Array(parseInt(item.stars) + 1).join('★')}
                </span>
            </div>
            ${item.rating > 0 ? `
                <div class="prop-type-and-rating">
                    ${item.proptype !== 0 ? allHoteltypesById[item.proptype].ru_name : 'Рейтинг'}
                    <span class="prop-rating">${item.rating / 10}</span>
                </div>
            ` : `
                ${item.proptype !== 0 ? `<div class="prop-type-and-rating">${allHoteltypesById[item.proptype].ru_name}</div>` : ''} 
            `}
            <div class="prop-address">${item.address}</div>
            <div class="goto-button">
                <button type="button" class="goto-button-elem"
                    onclick="window.open('${itemLink}')"
                    >${item.price === 0 ? 'Посмотреть цену' : `От ${item.price} руб.`}</button>
            </div>
        </div>
    `
}

function activePopupMobilePortraitTemplateFn(item)
{
    let itemLink = getPatchedItemLink(item.link)

    return `
        <div>
            <div class="image-wrp-outer">
                <div class="image-wrp-inner">
                    <img class="image-elem" 
                         src="https://photo.hotellook.com/image_v2/limit/h${item.id}_0/300/185.auto"
                         onclick="window.open('${itemLink}')"/>
                    <button type="button" class="goto-button"
                        onclick="window.open('${itemLink}')"
                        >${item.price === 0 ? 'Посмотреть цену' : `От ${item.price} руб.`}</button>
                </div>
            </div>
            <div class="details-col">
                <div class="prop-name-and-stars">
                    <a target="_blank" onclick="window.open('${itemLink}')"
                        class="prop-name">${item.title}</a>
                    <span class="prop-stars">
                        ${new Array(parseInt(item.stars) + 1).join('★')}
                    </span>
                </div>
                ${item.rating > 0 ? `
                    <div class="prop-type-and-rating"
                        >${item.proptype !== 0 ? allHoteltypesById[item.proptype].ru_name : 'Рейтинг'}
                        <span class="prop-rating">${item.rating / 10}</span></div>
                ` : `
                    ${item.proptype !== 0 ? `
                        <div class="prop-type-and-rating"
                        >${allHoteltypesById[item.proptype].ru_name}</div>` : ''} 
                `}
                <div class="prop-address">${item.address}</div>
            </div>
            <div style="clear: both"></div>
        </div>
        `
}

function activePopupMobileLandscapeTemplateFn(item)
{
    let itemLink = getPatchedItemLink(item.link)

    return `
        <div>
            <div class="image-wrapper">
                <img src="https://photo.hotellook.com/image_v2/limit/h${item.id}_0/190/110.auto"
                     class="image-elem" onclick="window.open('${itemLink}')"/>
                <div class="popup-close"
                    @click="close()"
                >
                    <div class="close-iconelem" style="transform: translate(8px, 14px) rotate(45deg);"></div>                
                    <div class="close-iconelem" style="transform: translate(8px, 14px) rotate(-45deg);"></div>                
                </div>
            </div>
            <div class="prop-name-and-stars">
                <a target="_blank" href="${itemLink}" class="prop-name">${item.title}</a>
                <span class="prop-stars">
                    ${new Array(parseInt(item.stars) + 1).join('★')}
                </span>
            </div>
            ${item.rating > 0 ? `
                <div class="prop-type-and-rating">
                    ${item.proptype !== 0 ? allHoteltypesById[item.proptype].ru_name : 'Рейтинг'}
                    <span class="prop-rating">${item.rating / 10}</span>
                </div>
            ` : `
                ${item.proptype !== 0 ? `<div class="prop-type-and-rating">${allHoteltypesById[item.proptype].ru_name}</div>` : ''} 
            `}
            <div class="prop-address">${item.address}</div>
            <div class="goto-button">
                <button type="button" class="goto-button-elem"
                    onclick="window.open('${itemLink}')"
                    >${item.price === 0 ? 'Посмотреть цену' : `От ${item.price} руб.`}</button>
            </div>
        </div>
    `
}
