export default {
    namespaced: true,
    state:      {
        value:   {
            typeKey:   null,
            direction: null
        },
        allowed: [],
        types:   [
            {typeKey: 'rating'},
            {typeKey: 'popularity'},
            {typeKey: 'price', defaultDir: 'asc'},
            {typeKey: 'dist_from_center', defaultDir: 'asc'},
            {typeKey: 'nearest_airport_dist'},
            {typeKey: 'nearest_beach_dist'},
            {typeKey: 'nearest_rail_dist'},
        ],
    },
    mutations:  {
        allowSortingType(state, typeKey)
        {

        },
        disallowSortingType(state, typeKey)
        {

        },
    },
}
