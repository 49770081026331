<template>
    <div style="
        position: fixed;
        top: 0;
        height: 24px;
        width: 24px;
        left: -300px;
        margin: 0 0 0 50%;
        background: white;
        padding: 24px 8px 5px 8px;
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
        overflow: hidden;
        z-index: 9999;
    "
         :style="{height: isOpen ? 'auto' : '24px', width: isOpen ? 'auto' : '24px'}"
    >
        <button type="button"
                @click="isOpen = !isOpen"
                style="position: absolute; left: 0; top: 0;"
        >&raquo;
        </button>
        <div style="position: relative; width: 100%; padding: 5px 0;">
            <div style="background: #eee; border: 1px solid #aaa;">
                <span>asUnfilteredString</span>:
                <span v-text="$store.getters['locationHash/asUnfilteredString']"></span>
            </div>
        </div>
        <div>
            <button type="button"
                    @click="setTestHash"
            >setTestHash
            </button>
        </div>
        <div style="position: relative; width: 100%; padding: 5px 0;">
            <div style="border: 1px solid #aaa;">
                <div style="background: #eee;">asUnfilteredObject:</div>
                <div v-for="(value, key) in $store.state.locationHash.asUnfilteredObject"
                     style="position: relative; height: 24px; line-height: 24px; border-top: 1px solid #aaa;"
                >
                    <span v-text="key"></span>
                    =
                    <span v-text="value"></span>
                    <button type="button"
                            @click="deleteParam(key)"
                            style="position: absolute; right: 0; top: 2px"
                    >x
                    </button>
                </div>
            </div>
            <div>
                <input type="text"
                       v-model="newParamKey">

                <input type="text"
                       v-model="newParamValue">

                <button type="button"
                        @click="setParam"
                >setParam
                </button>
            </div>
        </div>

        <div style="position: relative; width: 100%; padding: 5px 0;">
            <div style="border: 1px solid #aaa;">
                <div style="background: #eee;">getters['locationHash/asObject']:</div>
                <div v-for="(value, key) in $store.getters['locationHash/asObject']"
                     style="position: relative; height: 24px; line-height: 24px; border-top: 1px solid #aaa;"
                >
                    <span v-text="key"></span>
                    =
                    <span v-text="value"></span>
                </div>
            </div>
        </div>

        <button type="button"
                @click="testFunc123"
        >testFunc123
        </button>

    </div>
</template>

<script>
    export default {
        data()
        {
            return {
                isOpen:        false,
                newParamKey:   '',
                newParamValue: '',
            }
        },
        methods: {
            testFunc123()
            {
                // console.log(this.$store.getters)
                // console.log(this.$store.getters['locationHash/asObject'])
                // console.log(this.$store.state.locationHash)
            },
            setTestHash()
            {
                this.$store.dispatch('locationHash/setFromRawStringPush', 'amenities=36,38,39,41,42,43,46,49&hoteltype=30,19,26,28,27,21&price=16,15,14,11&rating=7,8,10&special=80,79&stars=4,3,2,1,5,6&surroundings=78,76&sort=rating')
            },
            setParam()
            {
                this.$store.dispatch('locationHash/setParamPush', [this.newParamKey, this.newParamValue])

                this.newParamKey = ''

                this.newParamValue = ''
            },
            deleteParam(key)
            {
                this.$store.dispatch('locationHash/deleteParamPush', key)
            },
        }
    }
</script>
