import useragent from 'useragent';

let parsedUa = useragent.parse(navigator.userAgent)

import Vue from "vue";
import Vuex from "vuex";
import modules from './js/store'
import hotelsMod from '#/hotels/store'

Vue.use(Vuex)

if (window.hotels)
{
    modules.hotels = hotelsMod
}

export default new Vuex.Store({
    modules,
    getters: {
        ua()
        {
            let vendor = parsedUa.family.toLowerCase().split(' ')[0],
                os     = parsedUa.os.family.toLowerCase(),
                device = (os === 'android') ? 'mobile' : 'desktop'

            return {vendor, os, device}
        },
        uaRaw()
        {
            return parsedUa
        }
    }
})
