export default {
    components: {
        feedback: {
            data(){
                return {
                    state: 'new',
                    isDialogVisible: false,
                    isSubmitted: false,
                    name: '',
                    email: '',
                    content: '',
                    isPrivacyPolicyAccepted: false,
                    mediaWidth: document.documentElement.clientWidth
                }
            },
            computed: {
                isNameError() {
                    return this.name.trim().length === 0
                },
                isEmailError(){
                    return this.email.trim().length === 0
                },
                isContentError(){
                    return this.content.trim().length === 0
                },
                isAnyError(){
                    return this.isNameError ||
                        this.isEmailError ||
                        this.isContentError ||
                        !this.isPrivacyPolicyAccepted
                }
            },
            mounted: function () {
                window.addEventListener('resize', this.handleResize)
            },
            beforeDestroy: function () {
                window.removeEventListener('resize', this.handleResize)
            },
            methods: {
                submit(){
                    this.isSubmitted = true;
                    if (!this.isAnyError) {
                        this.state = 'submitted';
                        $.request('onSend', {
                            data: {
                                name: this.name,
                                email: this.email,
                                content: this.content,
                                isPrivacyPolicyAccepted: this.isPrivacyPolicyAccepted,
                            }
                        });
                    }
                },
                handleResize (event) {
                    this.mediaWidth = document.documentElement.clientWidth
                }
            }
        }
    }
}