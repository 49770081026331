import MarkerPopup from './marker-popup'
import guessCurrentScreenType from './guess-current-screen-type';

export default MarkersOverlay

function MarkersOverlay(options)
{
    this.extend(MarkersOverlay, google.maps.OverlayView)

    this.setMap(options.map)
    this.allMarkers = options.markers
    this.storageKey = options.storageKey

    this.clusters = []

    this.markersPrepared = false

    this.component = options.component

    if (options.onNumMarkersInViewportBoundsChanged)
    {
        this.onNumMarkersInViewportBoundsChanged = options.onNumMarkersInViewportBoundsChanged
    }

    this.numMarkersInViewportBounds = 0

    this.isClusteringEnabled = false

    this.activePopup = new MarkerPopup()
    this.activeMarker = null
    this.prevActiveMarker = null

    this.hoverPopup = new MarkerPopup()
    this.hoverMarker = null
    this.prevHoverMarker = null

    this.zoomChangingNow = false
    this.zoomChangedRecently = false

    this.isTouchSupported = false

    this.loadVisitedData()

    google.maps.event.addListener(this.map, 'zoom_changed', () =>
    {
        this.zoomChangingNow = true
    })

    google.maps.event.addListener(this.map, 'idle', () =>
    {
        if (this.zoomChangingNow)
        {

            this.zoomChangingNow = false
            this.zoomChangedRecently = true
        }
    })

    window.addEventListener('touchstart', (e) =>
    {
        this.isTouchSupported = true

        this.hoverPopup.close()

        if (this.hoverMarker)
        {
            let hoverMarker = this.hoverMarker
            this.hoverMarker = null
            this.paintMarker(hoverMarker)
        }
    }, {
        passive: true
    })
}

MarkersOverlay.prototype.prepareMarkers = function ()
{
    if (this.markersPrepared)
    {
        return
    }

    this.markersPrepared = true

    for (let i = 0; i < this.allMarkers.length; i++)
    {
        let item = this.allMarkers[i]

        item.imageElem = document.createElement('div')
        item.imageElem.className = 'gmap-marker-image gmm-default'
        item.hoverAreaElem = document.createElement('div')
        item.hoverAreaElem.className = 'gmap-marker-hover-area'

        this.paintMarker(item)

        item.isElemInserted = false

        item.hoverAreaElem.addEventListener('click', () =>
        {
            this.deactivateCurrentMarker()

            this.hoverPopup.close()

            this.activePopup.close()

            this.activePopup.open({
                overlay: this,
                marker:  item,
                mode:    'active',
            })

            this.activeMarker = item
            this.paintMarker(item)

            this.setVisited(item)

            if (guessCurrentScreenType() === 'mobile-landscape')
            {
                let projection = this.getProjection(),
                    pixel      = projection.fromLatLngToContainerPixel(item.latLng)
                if (pixel.x < 210)
                {
                    this.map.panBy(pixel.x - 210, 0)
                }
            }
        })

        item.hoverAreaElem.addEventListener('mouseout', (e) =>
        {
            this.hoverPopup.close()

            if (this.hoverMarker)
            {
                let hoverMarker = this.hoverMarker
                this.hoverMarker = null
                this.paintMarker(hoverMarker)
            }
        })

        item.hoverAreaElem.addEventListener('mouseover', (e) =>
        {
            if (this.isTouchSupported)
            {
                return
            }

            this.hoverPopup.open({
                overlay: this,
                marker:  item,
                mode:    'hover',
            })

            this.hoverMarker = item
            this.paintMarker(item)
        })
    }
}

MarkersOverlay.prototype.deactivateCurrentMarker = function ()
{
    if (this.activeMarker)
    {
        let activeMarker = this.activeMarker
        this.activeMarker = null
        this.paintMarker(activeMarker)
    }
}

MarkersOverlay.prototype.draw = function ()
{
    let projection = this.getProjection()
    let clusterSizeLatLng = 256 * Math.pow(2, this.map.getZoom()) / 10000

    this.prepareMarkers()

    this.recalcMarkersIsInViewportBounds()

    if (this.map.zoom >= 17)
    {
        this.isClusteringEnabled = false
    }
    else
    {
        this.isClusteringEnabled = true
    }

    let docFragmentImages     = document.createDocumentFragment(),
        docFragmentHoverAreas = document.createDocumentFragment()

    this.clusters = []

    for (let i = 0; i < this.allMarkers.length; i++)
    {
        let item = this.allMarkers[i]

        if (!item.filterPassed || !item.isInViewportBounds)
        {
            if (item.isElemInserted)
            {
                item.imageElem.parentElement.removeChild(item.imageElem)
                item.hoverAreaElem.parentElement.removeChild(item.hoverAreaElem)
                item.isElemInserted = false
            }

            continue
        }

        if (this.isClusteringEnabled)
        {
            item.positionHash = Math.round(item.lng * clusterSizeLatLng) + 'x' + Math.round(item.lat * clusterSizeLatLng)
        }
        else
        {
            item.positionHash = item.id
        }

        if (!this.clusters[item.positionHash] || (this.activeMarker === item))
        {
            let pos = projection.fromLatLngToDivPixel(item.latLng)
            item.imageElem.style.left = pos.x + 'px'
            item.imageElem.style.top = pos.y + 'px'
            item.hoverAreaElem.style.left = pos.x + 'px'
            item.hoverAreaElem.style.top = pos.y + 'px'

            this.clusters[item.positionHash] = item

            if (!item.isElemInserted)
            {
                docFragmentImages.appendChild(item.imageElem)
                docFragmentHoverAreas.appendChild(item.hoverAreaElem)
                item.isElemInserted = true
            }
        }
        else
        {
            if (item.isElemInserted)
            {
                item.imageElem.parentElement.removeChild(item.imageElem)
                item.hoverAreaElem.parentElement.removeChild(item.hoverAreaElem)
                item.isElemInserted = false
            }
        }
    }

    this.getPanes().overlayImage.appendChild(docFragmentImages)
    this.getPanes().overlayMouseTarget.appendChild(docFragmentHoverAreas)
}

MarkersOverlay.prototype.loadVisitedData = function ()
{
    this.visitedHotelIds = localStorage.getItem('hotelsOnMap.visited[' + this.storageKey + ']')
    try
    {
        this.visitedHotelIds = JSON.parse(this.visitedHotelIds)
    } catch (exception)
    {
    }
    if (!Array.isArray(this.visitedHotelIds))
    {
        this.visitedHotelIds = []
    }
    this.allMarkers.forEach(item =>
    {
        item.isVisited = this.visitedHotelIds.indexOf(item.id) !== -1
    })
}

MarkersOverlay.prototype.onAdd = function ()
{
}

MarkersOverlay.prototype.onRemove = function ()
{
}

MarkersOverlay.prototype.paintMarker = function (item)
{
    let classes = ['gmap-marker-image']

    if (this.hoverMarker === item)
    {
        classes.push('gmm-hover')
    }

    if (this.activeMarker === item)
    {
        classes.push('gmm-active')
    }
    else
    {
        if (item.isVisited)
        {
            classes.push('gmm-visited')
        }
        else
        {
            classes.push('gmm-default')
        }
    }

    item.imageElem.className = classes.join(' ')
}

MarkersOverlay.prototype.recalcMarkersIsInViewportBounds = function ()
{
    let currentBounds = this.map.getBounds()

    this.numMarkersInViewportBounds = 0

    this.allMarkers.forEach(item =>
    {
        item.isInViewportBounds = currentBounds.contains(item.latLng);

        if (item.isInViewportBounds)
        {
            this.numMarkersInViewportBounds++
        }
    })

    if (this.onNumMarkersInViewportBoundsChanged)
    {
        this.onNumMarkersInViewportBoundsChanged()
    }
}

MarkersOverlay.prototype.saveVisitedData = function ()
{
    localStorage.setItem('hotelsOnMap.visited[' + this.storageKey + ']', JSON.stringify(this.visitedHotelIds))
}

MarkersOverlay.prototype.setVisited = function (item)
{
    if (item.isVisited) return;

    item.isVisited = true

    this.visitedHotelIds.push(item.id)

    this.saveVisitedData()
}

/**
 * Taken from marker-clusterer-plus
 */
MarkersOverlay.prototype.extend = function (obj1, obj2)
{
    return (function (object)
    {
        let property;
        for (property in object.prototype)
        {
            this.prototype[property] = object.prototype[property];
        }
        return this;
    }).apply(obj1, [obj2]);
};
