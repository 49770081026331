export default {
    bind(el, binding, vnode, oldVnode)
    {
        vnode.context.$nextTick(() =>
        {
            let componentElem      = vnode.context.$el,
                initialParent      = componentElem.parentNode,
                initialNextSibling = componentElem.nextSibling

            if (binding.value)
            {
                componentElem.parentNode.removeChild(componentElem)
                document.getElementById(binding.value).appendChild(componentElem)
            }

            vnode.context.$watch(binding.expression, (dockTarget) =>
            {
                componentElem.parentNode.removeChild(componentElem)

                if (dockTarget)
                {
                    document.getElementById(dockTarget).appendChild(componentElem)
                }
                else
                {
                    initialParent.insertBefore(componentElem, initialNextSibling)
                }
            })
        })
    }
}
