import common from '~/common'
import store from "./store";

export default {
    created()
    {
        this.$store.registerModule(this.storeKey.split('.'), store)
    },
    mixins:   [common],
    props:    {
        numHotels:              {
            type:     Number,
            required: true
        },
        startPageNumHotels:     {
            type:     Number,
            required: true
        },
        numHotelsInCity:        {
            type:     Number,
            required: true
        },
        initFiltering:          {
            type:     [Object, Array],
            required: false,
            default:  {}
        },
        categoryDefaultSorting: {
            type:     Object,
            required: false,
            default:  () => ({})
        },
        canFilter:              {
            type:     Object,
            required: true
        },
        canSort:                {
            type:     Object,
            required: true
        },
        storeKey:               {
            type:     String,
            required: true
        },
    },
    data()
    {
        let filtering = {
            stars:        [],
            rating:       [],
            price:        [],
            hoteltype:    [],
            amenities:    [],
            surroundings: [],
            special:      []
        }

        Object.keys(this.initFiltering).forEach(key =>
        {
            filtering[key] = this.initFiltering[key]
        })

        let sorting = {
            typeKey: this.categoryDefaultSorting.typeKey,
        }

        if (this.categoryDefaultSorting.direction)
        {
            sorting.direction = this.categoryDefaultSorting.direction
        }

        return {
            cntHotelsFound:             this.numHotels,
            extraPages:                 [],
            loading:                    false,
            numHotelsLoaded:            this.startPageNumHotels,
            showInitialPage:            true,
            hashStr:                    '',
            filtering:                  filtering,
            sorting:                    sorting,
            pageNum:                    1,
            numPages:                   1,
            sidebarOverride:            null,
            adminPanelData:             {},
            smallScreenShowFilters:     false,
            hgls:                       {
                onLoadAppendData: [],
                onLoadSuccess:    [],
            },
            dontListenHashParamsChange: false
        }
    },
    mounted()
    {
        this.$store.commit('hotels/gridlist/setCntHotelsFound', this.numHotels)
    },
    computed: {
        smallScreenShowFoundItemsButtonText()
        {
            return 'Показать ' + this.cntHotelsFound + ' ' + this.plnum(['вариант', 'варианта', 'вариантов'], this.cntHotelsFound)
        },
        textCntHotelsLoadedOfCurrent()
        {
            return 'Найдено: ' + this.cntHotelsFound + ' из ' + this.numHotelsInCity + ' вариантов'
        },
        numExtraPagesLoaded()
        {
            return this.extraPages.length
        },
        numPagesLoaded()
        {
            return this.numExtraPagesLoaded + (this.showInitialPage ? 1 : 0)
        },
        numPagesPossible()
        {
            return Math.ceil(this.numHotels / 12)
        },
        state()
        {
            return this.storeKey.split('.').reduce((acc, value) => acc[value], this.$store.state)
        },
    },
    watch:    {
        smallScreenShowFilters(val)
        {
            document.body.classList[val ? 'add' : 'remove']('no-scroll-on-small-screen')
        },
    },
    methods:  {
        onHashParamsChanged(hashStr, paramsData)
        {
            if (this.dontListenHashParamsChange)
            {
                return
            }

            this.pageNum = 1

            this.hashStr = paramsData.hashStr
            this.filtering = paramsData.filtering
            this.sorting = paramsData.sorting

            if (!this.sorting.typeKey)
            {
                this.sorting = this.categoryDefaultSorting
            }

            this.update({initiator: 'hashParams'})
        },
        onFilteringChanged(groupCode, groupUiCode, filterId, filtering)
        {
            this.reachGoal('hotels_gridlist_filter_' + groupUiCode + '_clicked')

            this.pageNum = 1

            this.filtering = filtering

            this.update({initiator: 'filtering'})
        },
        onSortingChanged(sorting)
        {
            this.reachGoal('hotels_gridlist_sort_by_' + this.sorting.typeKey + '_clicked')

            this.pageNum = 1

            this.dontListenHashParamsChange = true

            this.sorting = sorting

            this.update({initiator: 'sorting'})
        },
        onShowmoreClicked()
        {
            this.reachGoal('hotels_gridlist_showmore_clicked_' + (this.numPagesLoaded > 5 ? 5 : this.numPagesLoaded))

            this.pageNum = this.pageNum + 1

            this.update({initiator: 'showmoreButton'})
        },
        resetFilters()
        {
            this.pageNum = 1

            Object.keys(this.filtering).forEach(key =>
            {
                this.$set(this.filtering, key, [])
            })

            this.update({initiator: 'resetFiltersButton', isFilteringDirty: true})
        },
        update(data = {})
        {
            $(document).ready(() =>
            {
                setTimeout(() =>
                {
                    if (this.loading && (data.initiator === 'hashParams'))
                    {
                        return
                    }

                    this.loading = true

                    data.pageNum = this.pageNum

                    for (let i = 0; i < this.hgls.onLoadAppendData.length; i++)
                    {
                        this.hgls.onLoadAppendData[i](data)
                    }

                    // this.$store.commit('hotels/gridlist/requestStarted', data)

                    $.request('hotelsGridlist::onLoad', {
                        data,
                        success: (result) =>
                                 {
                                     for (let i = 0; i < this.hgls.onLoadSuccess.length; i++)
                                     {
                                         this.hgls.onLoadSuccess[i](data, result)
                                     }

                                     this.cntHotelsFound = result.cntItems

                                     this.$store.commit('hotels/gridlist/setCntHotelsFound', result.cntItems)

                                     this.$store.commit('hotels/gridlist/setCntPagesDisplayed', this.pageNum)

                                     this.loading = false

                                     this.dontListenHashParamsChange = false
                                 }
                    })
                }, 100)
            })
        },
    }
}
