export default class Stars {
    constructor() {
        this.items = getItems()
        this.items.forEach(item => item.checked = false)
    }

    reset() {
        this.items.forEach(item => item.checked = false)
    }
}

function getItems() {
    let result = [], i, item
    for (i = 1; i <= 5; i++) {
        item = {
            numDisplayStars: i,
            value:           i,
            checked:         false,
            pale:            false
        }
        if (i >= 3 && i <= 5) item.hotelgroup = i + 'stars'
        result.push(item)
    }
    result.push({
        numDisplayStars: 5,
        value:           0,
        checked:         false,
        pale:            true
    })
    return result
}