export default {
    computed:   {
        w()
        {
            return window
        },
        uaRaw()
        {
            return this.$store.getters.uaRaw
        },
        ua()
        {
            return this.$store.getters.ua
        },
    },
    methods:    {
        isElemIntersectsViewport(elem)
        {
            let boundingRect = elem.getBoundingClientRect()

            return (boundingRect.top <= document.documentElement.clientHeight) &&
                ((boundingRect.top + boundingRect.height) >= 0)
        },
        plnum(wordForms, number)
        {
            if (((number % 100) > 4) && ((number % 100) < 20))
            {
                return wordForms[2];
            }
            else
            {
                switch (number % 10)
                {
                    case 0:
                        return wordForms[2];
                    case 1:
                        return wordForms[0];
                    case 2:
                    case 3:
                    case 4:
                        return wordForms[1];
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    default:
                        return wordForms[2];
                }
            }
        },
    },
    directives: {}
}