export default {
    template: '<div></div>'/*`
    <div>
        <div style="position: fixed; left: 0; top: 26px; padding: 2px 5px; background: white; box-shadow: 0 0 3px 0 rgba(0,0,0,0.5); z-index: 999">
            <div :style="{display: isControlsExpanded ? 'block' : 'none'}">
                <button type="button"
                        @click="$store.dispatch('browser/toggleScrollable')"
                        :style="{background: $store.state.browser.isScrollable ? '#eee' : 'orange'}"
                        v-text="$store.state.browser.isScrollable ? 'scrollable' : 'not scrollable'"
                ></button>
                <br/>
                <button type="button"
                        @click="$store.commit('hotels/map/toggleVisible', {followFullscreen: false})"
                        :style="{background: ($store.state.hotels.map && $store.state.hotels.map.isVisible) ? 'orange' : '#eee'}"
                        v-text="($store.state.hotels.map && $store.state.hotels.map.isVisible) ? 'map visible' : 'map not visible'"
                ></button>
                <br/>
                <button type="button"
                        @click="$store.commit('hotels/map/toggleFullscreen')"
                        :style="{background: ($store.state.hotels.map && $store.state.hotels.map.isFullscreen) ? 'orange' : '#eee'}"
                        v-text="($store.state.hotels.map && $store.state.hotels.map.isFullscreen) ? 'fullscreen' : 'not fullscreen'"
                ></button>
                <div style="white-space: pre" v-text="$store.state.hotels.map"></div>
                <div style="white-space: pre" v-text="$store.state.browser"></div>
            </div>
            <button type="button"
                    @click="isControlsExpanded = !isControlsExpanded"
                    v-html="isControlsExpanded ? '&laquo;' : '&raquo;'"
            ></button>
            
            <!--<div style="padding: 5px 10px; background: #abcdef"
                v-modal="isHotelsMapModal"
            >
                <div>qwerasdf</div>
                <button type="button"
                        @click="isHotelsMapModal = !isHotelsMapModal"
                        v-html="isHotelsMapModal ? '&laquo;' : '&raquo;'"
                ></button>
            </div>-->
            
        </div>
    </div>
    `*/,
    props:    {
        dataCountryId: {
            type:     String,
            required: true
        },
        dataCityId:    {
            type:     String,
            required: true
        },
        dataChcatId:   {
            type:     String,
            required: true
        },
        dataWwwpath:   {
            type:     String,
            required: true
        },
        dataPageType:  {
            type:     String,
            required: true
        },
    },
    data()
    {
        return {
            isModalMapOpen:     false,
            isControlsExpanded: false,
            isHotelsMapModal:   false
        }
    },
    mounted()
    {
        this.$root.hotels = {
            countryId: this.dataCountryId,
            cityId:    this.dataCityId,
            chcatId:   this.dataChcatId,
            wwwpath:   this.dataWwwpath,
            pageType:  this.dataPageType,
        }
    },
}
