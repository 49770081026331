export default class PropertyTypes {
    constructor() {
        this.items = getItems()
        this.items.forEach(item => item.checked = false)
    }

    reset() {
        this.items.forEach(item => item.checked = false)
    }
}

function getItems() {
    return [
        {
            htlkName: 'Отель',
            htlkKey:  '1',
            bcName:   'Отели',
            bcKey:    '204'
        },
        {
            htlkName: 'Апартотель',
            htlkKey:  '2',
            bcName:   '',
            bcKey:    null
        },
        {
            htlkName: 'Ночлег и завтрак',
            htlkKey:  '3',
            bcName:   'Ночлег и завтрак',
            bcKey:    '208'
        },
        {
            htlkName:   'Апартаменты',
            htlkKey:    '4',
            bcName:     'Апартаменты',
            bcKey:      '201',
            hotelgroup: 'apts'
        },
        {
            htlkName: 'Мотель',
            htlkKey:  '5',
            bcName:   '',
            bcKey:    null
        },
        {
            htlkName:   'Гостевой дом',
            htlkKey:    '6',
            bcName:     'Гостевые дома',
            bcKey:      '216',
            hotelgroup: 'guesths'
        },
        {
            htlkName:   'Хостел',
            htlkKey:    '7',
            bcName:     'Хостелы',
            bcKey:      '203',
            hotelgroup: 'hostels'
        },
        {
            htlkName: 'Резорт',
            htlkKey:  '8',
            bcName:   '',
            bcKey:    null
        },
        {
            htlkName: 'Вилла',
            htlkKey:  '12',
            bcName:   'Виллы',
            bcKey:    '213'
        },
        {
            htlkName: 'Другое',
            htlkKey:  '0',
            bcName:   '',
            bcKey:    null
        },
        // Here go booking.com property types
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Дома для отпуска',
            bcKey:    '220'
        },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Загородные дома',
            bcKey:    '223'
        },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Шале',
            bcKey:    '228'
        },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Фермерские дома',
            bcKey:    '210'
        },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Ботели',
            bcKey:    '215'
        },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Курортные отели',
            bcKey:    '206'
        },
        // {
        //     htlkName: null,
        //     htlkKey: null,
        //     bcName: 'Проживание в семье',
        //     bcKey: '222'
        // },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Кемпинги',
            bcKey:    '214'
        },
        {
            htlkName: null,
            htlkKey:  null,
            bcName:   'Люкс-шатры',
            bcKey:    '224'
        },
        // {
        //     htlkName: null,
        //     htlkKey: null,
        //     bcName: 'Комплексы для отдыха с коттеджами/бунгало',
        //     bcKey: '212'
        // },
    ]
}