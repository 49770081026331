import common from '~/common'

export default {
    mixins:  [common],
    data()
    {
        return {
            showDebugDetails:   false,
            imagesSlickOptions: {
                prevArrow:     '<button type="button" class="prev fa fa-angle-left"></button>',
                nextArrow:     '<button type="button" class="next fa fa-angle-right"></button>',
                focusOnSelect: true,
                slidesToShow:  1,
                lazyLoad:      'anticipated',
            },
            currentSlideIndex:  1,
            sliderActivated:    false,
        }
    },
    watch:   {
        '$store.state.hotels.gridlist.itemsLayout'()
        {
            this.currentSlideIndex = 1
        }
    },
    methods: {
        beforeChange(event, slick, oldValue, value)
        {
            this.currentSlideIndex = value + 1
        },
    },
}
