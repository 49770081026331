export default {
    namespaced: true,
    state:      {
        width:           window.innerWidth,
        height:          window.innerHeight,
        scrollX:         window.scrollX,
        scrollY:         window.scrollY,
        isScrollable:    true,
        isTouchDetected: false,
    },
    mutations:  {
        widthChanged(state, value)
        {
            state.width = value
        },
        heightChanged(state, value)
        {
            state.height = value
        },
        scrollXChanged(state, value)
        {
            state.scrollX = value
        },
        scrollYChanged(state, value)
        {
            state.scrollY = value
        },
        touchWasDetected(state)
        {
            state.isTouchDetected = true
        },
        setScrollable(state, isScrollable)
        {
            state.isScrollable = isScrollable
        },/*
        setLocationHash(state, value)
        {
            state.locationHash = value
            state.locationHashObj = parseLocationHash(value)
        },
        setLocationHashObj(state, value)
        {
            state.locationHash = stringifyLocationHash(value)
            state.locationHashObj = value
        },
        setLocationHashParam(state, keyValue)
        {
            state.locationHashObj[keyValue[0]] = keyValue[1]
            state.locationHash = stringifyLocationHash(state.locationHashObj)
        },*/
    },
    actions:    {
        setScrollable(context, isScrollable)
        {
            context.commit('setScrollable', isScrollable)

            document.body.style.overflow = (isScrollable ? 'auto' : 'hidden')

            document.body.style.marginRight = (isScrollable ? '0' : '17px')
        },
        toggleScrollable(context)
        {
            context.dispatch('setScrollable', !context.state.isScrollable)
        },/*
        locationHashChanged(context, value)
        {
            context.commit('setLocationHash', value)
        },
        setLocationHash(context, value)
        {
            context.commit('setLocationHash', value)

            window.location.hash = value;
        },
        setLocationHashObj(context, value)
        {
            context.commit('setLocationHashObj', value)

            window.location.hash = context.state.locationHash
        },
        setLocationHashParam(context, key, value)
        {
            context.commit('setLocationHashParam', key, value)

            window.location.hash = context.state.locationHash
        },*/
    }
}
