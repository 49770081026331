<template>
    <label :class="['nt-checkbox', labelPosition === 'left' ? 'label-at-left' : 'label-at-right']">
        <span class="nt-checkbox__input"
              :class="{
            'is-disabled': disabled,
            'is-checked': isChecked,
            'is-focus': focus
          }"
        >
          <span class="nt-checkbox__inner"></span>
          <input
                  class="nt-checkbox__original"
                  :aria-label="($slots.default ? $slots.default[0].text : label).replace(/[\s]+/g, ' ').replace(/(^\s)|(\s$)+/g, '')"
                  type="checkbox"
                  :disabled="disabled"
                  :value="label"
                  :name="name"
                  v-model="model"
                  @change="handleChange"
                  @focus="focus = true"
                  @blur="focus = false"/>
        </span>
        <span class="nt-checkbox__label" v-if="$slots.default || label">
            <slot></slot>
            <template v-if="!$slots.default">{{label}}</template>
        </span>
    </label>
</template>
<script>
    export default {
        name: 'NtCheckbox',
        data()
        {
            return {
                selfModel: false,
                focus: false
            };
        },
        computed: {
            model: {
                get()
                {
                    return this.value !== undefined ? this.value : this.selfModel;
                },
                set(val)
                {
                    this.$emit('input', val);
                    this.selfModel = val;
                }
            },
            isChecked()
            {
                let modelType = {}.toString.call(this.model)
                if (modelType === '[object Boolean]')
                {
                    return this.model
                }
                else if (modelType === '[object Number]')
                {
                    return this.model !== 0
                }
                else if (Array.isArray(this.model))
                {
                    return this.model.indexOf(this.label) > -1
                }
                else
                {
                    return this.model !== null && this.model !== undefined
                }
            },
            store()
            {
                return this._checkboxGroup ? this._checkboxGroup.value : this.value;
            }
        },
        props: {
            value: {},
            label: {},
            labelPosition: {
                type: String,
                default: 'right'
            },
            disabled: Boolean,
            checked: Boolean,
            name: String
        },
        methods: {
            addToStore()
            {
                if (
                    Array.isArray(this.model) &&
                    this.model.indexOf(this.label) === -1
                )
                {
                    this.model.push(this.label);
                }
                else
                {
                    this.model = true;
                }
            },
            handleChange(ev)
            {
                this.$emit('change', ev);
            }
        },
        created()
        {
            this.checked && this.addToStore();
        }
    };
</script>
