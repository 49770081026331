import {storeAtKeyMixin} from '~/js/storeAtKeyHelper'

export default {
    mixins:   [storeAtKeyMixin],
    props:    {
        hgls:            {
            type:     Object,
            required: true
        },
        filtering:       {
            type:     Object,
            required: true
        },
        canFilter:       {
            type:     Object,
            required: true
        },
        initHotelCounts: {
            type:     [Object, Array],
            required: true
        }
    },
    data()
    {
        return {
            sidebarOverride: null,
            isDirty:         location.hash.replace(/^#/, '').length > 0,
            chtagCounts:     this.initHotelCounts,
        }
    },
    computed: {},
    mounted()
    {
        this.hgls.onLoadAppendData.push(data =>
        {
            data.filtering = this.filtering

            if (data.isFilteringDirty === undefined)
            {
                data.isFilteringDirty = this.isDirty
            }
        })

        this.hgls.onLoadSuccess.push((input, result) =>
        {
            if (result.chtag_counts)
            {
                this.chtagCounts = result.chtag_counts
            }
        })
    },
    methods:  {
        addAllowedGroup(definition)
        {
            vnode.context.storeCommit('addAllowedGroup',)
        },
        isChecked(groupCommonCode, filterId)
        {
            if (!this.filtering[groupCommonCode])
            {
                return false
            }

            return this.filtering[groupCommonCode].indexOf(Number(filterId)) !== -1
        },
        onCheckboxClicked(groupCommonCode, filterId, groupUiCode)
        {
            this.$store.commit('hotels/gridlist/filtering/toggleFilter', [groupCommonCode, filterId])

            let filtering = JSON.parse(JSON.stringify(this.filtering)),
                index

            if (!filtering[groupCommonCode])
            {
                filtering[groupCommonCode] = []
            }

            index = filtering[groupCommonCode].indexOf(filterId)

            if (index === -1)
            {
                filtering[groupCommonCode].push(filterId)
            }
            else
            {
                filtering[groupCommonCode].splice(index, 1)
            }

            this.isDirty = true

            this.$emit('change', groupCommonCode, groupUiCode, filterId, filtering)
        },
    }
}
