import sliderStep from './price-slider-step'

export default {
    props:    [
        'price-range',
        'price-median'
    ],
    data()
    {
        return {
            sliderOptions: {
                dotSize:  32,
                realTime: true,
                lazy:     false,
                tooltip:  false,
            },
            value:         []
        }
    },
    methods:  {
        onValueChanged()
        {
            this.$emit('value-change', this.transformedValue)
        }
    },
    computed: {
        transformedValue()
        {
            let min  = this.priceRange[0],
                max  = this.priceRange[1],
                avg  = (min + max) / 2,
                from = medianAdjustedValue(this.value[0], this.priceMedian, min, max, avg),
                to   = medianAdjustedValue(this.value[1], this.priceMedian, min, max, avg)

            from = Math.round(from / sliderStep) * sliderStep
            to = Math.round(to / sliderStep) * sliderStep

            return [from, to]
        }
    },
    watch:    {
        '$parent.storeAtKey.isFullscreen'()
        {
            this.$refs.slider.refresh()
        },
        priceRange(value)
        {
            this.value = value
            this.sliderOptions.min = value[0]
            this.sliderOptions.max = value[1]
        },
    },
}

function medianAdjustedValue(value, median, min, max, avg)
{
    if (value < avg)
    {
        return Math.round(((value - min) / (avg - min) * (median - min)) + min)
    }
    else
    {
        return Math.round(((value - avg) / (max - avg) * (max - median)) + median)
    }
}
