export const parseHash = hashStr =>
{
    let result = {}

    hashStr.split('&').forEach(item =>
    {
        if (!item.length)
        {
            return
        }

        let [key, value] = item.split('=')

        result[key] = value
    })

    return result
}

export const stringifyHash = hashObj =>
{
    let result = []

    for (let key in hashObj)
    {
        if (!hashObj.hasOwnProperty(key))
        {
            continue
        }

        if (hashObj[key] !== undefined && String(hashObj[key]).length > 0)
        {
            result.push(key + '=' + hashObj[key])
        }
        else
        {
            result.push(key)
        }
    }

    return result.join('&')
}

export const getCurrentHash = () =>
{
    return (window.location.hash || '').replace(/^#/, '')
}

export const setCurrentHash = (value, saveToHistory) =>
{
    if (value === getCurrentHash())
    {
        return
    }

    let hashObj = parseHash(value),
        base    = window.location.origin + window.location.pathname,
        fn      = saveToHistory ? 'pushState' : 'replaceState'

    if (value.length)
    {
        history[fn](hashObj, null, base + '#' + value)
    }
    else
    {
        history[fn](hashObj, null, base)
    }
}
