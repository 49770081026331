export default {
    allUtmParamsOrdered: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'],
    loadUtm() {

        if (this.loaded === true) {

            return
        }

        let queryRaw = window.location.search.replace(/^\?/, '').split('&'),
            query = {},
            parts,
            result = {},
            hasAnyUtmParams = false
        for (let param of queryRaw) {
            parts = param.split('=')
            query[parts[0]] = parts[1]
        }
        for (let param in query) {
            if (query.hasOwnProperty(param)) {
                if (this.allUtmParamsOrdered.includes(param)) {
                    result[param] = query[param]
                    hasAnyUtmParams = true
                }
            }
        }
        if (hasAnyUtmParams) {
            result.time = new Date().getTime()
            window.localStorage.setItem('from_utm', JSON.stringify(result))
        }

        this.loaded = true
    },
    appendLabelToUrl(url) {
        let
            from_utm = window.localStorage.getItem('from_utm'),
            result = url

        if (from_utm) {

            from_utm = JSON.parse(from_utm)

            let hours_passed = (new Date().getTime() - from_utm.time) / 3600000

            if (hours_passed < 48) {

                result = new URL(url)

                let utm_label_fragment = ''

                for (let i = 0; i < this.allUtmParamsOrdered.length; i++) {

                    let param = this.allUtmParamsOrdered[i]

                    if (from_utm[param]) {

                        utm_label_fragment += '-' + from_utm[param]
                    }
                }

                if (utm_label_fragment.length) {

                    result.searchParams.forEach(function (value, key, parent) {

                        if (key === 'label') {

                            parent.set(key, value + '-utm' + utm_label_fragment)
                        }
                    })
                }

                result = result.toString()
            }
        }

        return result
    },
}