// import hoteltypeCodes from "./hoteltype-codes";

export default {
    props:   {
        hoteltypes:         Object,
        initFiltersChecked: {
            type:    Array,
            default: function ()
                     {
                         return []
                     }
        }
    },
    data()
    {
        return {
            value:          [],
            orderedIndices: [
                5,      // Отель
                14,     // Апартотель
                12,     // Ночлег и завтрак
                10,     // Апартаменты
                4,      // Хостел
                3,      // Гостевой дом
                2,      // Вилла
                8,      // Небольшой дом
                1,      // Другое
            ],
        }
    },
    mounted()
    {
        this.$nextTick(() =>
        {
            if (this.initFiltersChecked.length)
            {
                this.initFiltersChecked.forEach(item =>
                {
                    this.setFilter(hoteltypeCodes[item], true)
                })
            }
        })
    },
    watch:   {
        hoteltypes(value)
        {
            Object.keys(value).forEach(
                key => this.value[key] = false
            )

            if (this.initFiltersChecked.length)
            {
                this.initFiltersChecked.forEach(item =>
                {
                    this.setFilter(hoteltypeCodes[item], true)
                })
            }
        }
    },
    methods: {
        toggleFilter(key)
        {
            this.value.splice(key, 1, !this.value[key])
            this.$emit('value-change', this.value)
        },
        setFilter(key, value)
        {
            this.value.splice(key, 1, value)
            this.$emit('value-change', this.value)
        },
    }
}
