export default {
    template: '<div></div>',
    props:    {
        hgls:      {
            type:     Object,
            required: true
        },
        filtering: {
            type:     Object,
            required: true
        },
        sorting:   {
            type:     Object,
            required: true
        },
        canFilter: {
            type:     Object,
            required: true
        },
        canSort:   {
            type:     Object,
            required: true
        }
    },
    mounted()
    {
        this.hgls.onLoadAppendData.push(data =>
        {
            data.hashParams = this.hashStr
        })

        // let data = this.hashToObj(getLocationHash())
        //
        // this.hashStr = this.objToHash(data)
        //
        // if (this.hashStr.length)
        // {
        //     this.$emit('change', this.hashStr, data)
        // }

        this.$store.commit('locationHash/addParamsMiddleware', ['hotelsGridlist', paramsObj =>
        {
            for (let groupCode in this.canFilter)
            {
                if (!this.canFilter.hasOwnProperty(groupCode))
                {
                    continue
                }

                let canFilter = this.canFilter[groupCode]

                if (paramsObj.hasOwnProperty(groupCode))
                {
                    let unfilteredIds = paramsObj[groupCode],
                        ids           = unfilteredIds
                            .split(',')
                            .filter(id =>
                                canFilter.includes(Number(id))
                            )
                            .join(',')

                    if (!ids.length)
                    {
                        delete paramsObj[groupCode]
                    }
                    else
                    {
                        if (ids !== unfilteredIds)
                        {
                            paramsObj[groupCode] = ids
                        }
                    }
                }
            }

            if (paramsObj.hasOwnProperty('sort'))
            {
                if (!this.canSort.hasOwnProperty(paramsObj.sort))
                {
                    paramsObj.sort = 'rating'
                }

                let defaultDirection = this.canSort[paramsObj.sort]

                if (['asc', 'desc'].includes(defaultDirection))
                {
                    if (!['asc', 'desc'].includes(paramsObj.dir))
                    {
                        paramsObj.dir = defaultDirection
                    }
                }
                else
                {
                    delete paramsObj.dir
                }
            }
            else
            {
            }

            return Object.keys(this.canFilter).concat(['sort', 'dir'])
        }])
    },
    watch:    {
        hashStr(value)
        {
            this.$emit('change', this.hashStr, this.asStructuredObj)
        },
        filtering: {
            handler()
            {
                for (let key in this.filtering)
                {
                    if (!this.filtering.hasOwnProperty(key))
                    {
                        continue
                    }

                    if (this.filtering[key].length)
                    {
                        this.$store.dispatch('locationHash/setParamReplace', [key, this.filtering[key]])
                    }
                    else
                    {
                        this.$store.dispatch('locationHash/deleteParamReplace', key)
                    }
                }
            },
            deep: true
        },
        sorting:   {
            handler()
            {
                this.$store.dispatch('locationHash/setParamReplace', ['sort', this.sorting.typeKey])
                this.$store.dispatch('locationHash/setParamReplace', ['dir', this.sorting.direction])
            },
            deep: true
        }
    },
    computed: {
        hashStr()
        {
            return this.$store.getters['locationHash/asString']
        },
        asStructuredObj()
        {
            let result = {
                    filtering: {},
                    sorting:   {}
                },
                params = this.$store.getters['locationHash/asObject']

            for (let groupCode in this.canFilter)
            {
                if (!this.canFilter.hasOwnProperty(groupCode))
                {
                    continue
                }

                if (params.hasOwnProperty(groupCode))
                {
                    result.filtering[groupCode] = params[groupCode].split(',').map(item => Number(item))
                }
            }

            if (params.hasOwnProperty('sort'))
            {
                result.sorting.typeKey = params.sort
            }

            if (params.hasOwnProperty('dir'))
            {
                result.sorting.direction = params.dir
            }

            return result
        }
    },
}
