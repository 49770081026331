export default {
    namespaced: true,
    state:      {
        value:     {
            stars:        [],
            rating:       [],
            price:        [],
            hoteltype:    [],
            amenities:    [],
            surroundings: [],
            special:      [],
        },
        allowed:   {},
        isUiDirty: false
    },
    mutations:  {
        setFilter(state, [groupCode, id, isChecked])
        {
        },
        toggleFilter(state, [groupCode, id])
        {
            let index = state.value[groupCode].indexOf(Number(id))

            if (index === -1)
            {
                state.value[groupCode].push(id)
            }
            else
            {
                state.value[groupCode].splice(index, 1)
            }

            state.isUiDirty = true
        },
        addAllowedGroup(state, definition)
        {
            state.allowed.push(definition)
        }
    }
}
