import {parseHash, stringifyHash, getCurrentHash, setCurrentHash} from '~/js/location-hash/util'

export default {
    namespaced: true,
    state()
    {
        return {
            isFullscreen: false,
            isVisible:    getCurrentHash().includes('map'),

            isLoadingMapData: false,
            isMapReady:       false,
        }
    },
    mutations:  {
        setFullscreen(state, value)
        {
            state.isFullscreen = value
        },
        setVisible(state, value)
        {
            state.isVisible = value
        },
        toggleFullscreen(state)
        {
            state.isFullscreen = !state.isFullscreen
        },
        toggleVisible(state)
        {
            state.isVisible = !state.isVisible
        },
        setLoadingMapData(state, value)
        {
            state.isLoadingMapData = value
        },
        setMapReady(state, value)
        {
            state.isMapReady = value
        },
    },
    actions:    {
        modalLayoutToggleVisible({commit, state})
        {
            commit('setVisible', !state.isVisible)
            commit('setFullscreen', state.isVisible)
        },
        modalLayoutSetVisible({commit}, value)
        {
            commit('setVisible', value)
            commit('setFullscreen', value)
        },
        inlineLayoutToggleFullscreen({commit, state})
        {
            commit('setFullscreen', !state.isFullscreen)
        },
        inlineLayoutSetFullscreen({commit}, value)
        {
            commit('setFullscreen', value)
        }
    }
}
