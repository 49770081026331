export default {
    props: {
        activeTabId: String
    },
    data() {
        return {}
    },
    methods: {
        setActiveTab(tabId){
            this.activeTabId = tabId
        },
        getActiveTab(){
            return this.activeTabId
        },
        isTabActive(tabId){
            return this.activeTabId === tabId
        }
    }
}