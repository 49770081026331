export default {
    namespaced: true,
    state:      {
        asObject: {},
        isDirty:  false,
    },
    getters:    {
        asString()
        {

        },
        isAbsent()
        {

        }
    }
}
