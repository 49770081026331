import Vue from 'vue'
import common from '~/common'
import moment from 'moment'
import PropertyTypes from './property-types'
import Amenities from './amenities'
import Stars from './stars'
import axios from 'axios'
import utm from '~/partials/hotels/utm'

let htlkToBcAutocompleteCache = {
    locations: {},
    hotels:    {}
}

function getCorrespondingBcUrl(htlkItem, callback)
{
    let itemType = htlkItem.hotelsCount === undefined ? 'hotels' : 'locations',
        itemId   = htlkItem.id
    if (!htlkToBcAutocompleteCache[itemType][itemId])
    {
        axios.get('/matchHtlkToBcAutocomplete/' + itemType + '/' + itemId)
            .then(function (result)
            {
                callback(htlkToBcAutocompleteCache[itemType][itemId] = result.data);
            })
    }
    else
    {
        callback(htlkToBcAutocompleteCache[itemType][itemId]);
    }
}

export default {
    props:    [
        'initLocationId',
        'initLocationName',
        'initLocationHotelsCount',
        'initLocationBcUrl',
        'initHotelgroupName',
        'initCustomBcQuery',
        'initCategoryCode'
    ],
    data:     function ()
              {
                  let vm = this

                  function datepickerCommon(config)
                  {
                      let earliestAllowed = moment().startOf('day')
                      if (config.type === 'out') earliestAllowed.add(1, 'days')
                      let self = {
                          isOpen:           false,
                          isPristine:       true,
                          value:            null,
                          drawMonthOvrd:    false,
                          drawYearOvrd:     false,
                          monthWidth:       320,
                          get totalWidth()
                          {
                              return self.monthWidth * self.picker('option', 'numberOfMonths')
                          },
                          config:           {
                              showOtherMonths:   true,
                              selectOtherMonths: true,
                              numberOfMonths:    2,
                              cellWidth:         25,
                              showOn:            null,
                              changeMonth:       false,
                              changeYear:        false,
                              highlightWeek:     true,
                              dateFormat:        'dd MM yy',
                              prevText:          '&nbsp;',
                              nextText:          '&nbsp;',
                              onClose:           () => Vue.nextTick(() => self.isOpen = false),
                              onSelect:          () => Vue.nextTick(() =>
                              {
                                  self.isPristine = false
                                  let value = self.picker('getDate')
                                  self.value = value ? moment(value) : null
                              }),
                              beforeShow:        function (input, picker)
                                                 {
                                                     let numMonths = $(window).innerWidth() < 640 ? 1 : 2,
                                                         drawYearOvrd, drawMonthOvrd
                                                     self.picker('option', 'numberOfMonths', numMonths)
                                                     if (self.type === 'in')
                                                     {
                                                         if (!self.value && vm.checkout.value)
                                                         {
                                                             drawYearOvrd = vm.checkout.value.year()
                                                             drawMonthOvrd = vm.checkout.value.month() - (numMonths == 1 ? 0 : 1)
                                                             if (drawMonthOvrd < 0)
                                                             {
                                                                 drawMonthOvrd = 11
                                                                 drawYearOvrd--
                                                             }
                                                         }
                                                     }
                                                     else
                                                     {
                                                         if (!self.value && vm.checkin.value)
                                                         {
                                                             drawYearOvrd = vm.checkin.value.year()
                                                             drawMonthOvrd = vm.checkin.value.month()
                                                         }
                                                     }
                                                     if (drawMonthOvrd && drawYearOvrd)
                                                     {
                                                         setTimeout(() =>
                                                         {
                                                             let inst = self.$elem().data('datepicker')
                                                             inst.drawMonth = inst.selectedMonth = drawMonthOvrd
                                                             inst.drawYear = inst.selectedYear = drawYearOvrd
                                                             $.datepicker._updateDatepicker(inst)
                                                         })
                                                     }
                                                     self.isOpen = true
                                                 },
                              beforeShowDay:     (date) =>
                                                 {
                                                     let classes      = [],
                                                         current      = moment(date),
                                                         isSelectable = true
                                                     if (current.isBefore(earliestAllowed))
                                                     {
                                                         isSelectable = false
                                                     }
                                                     if (vm.checkin.value && vm.checkout.value &&
                                                         current.isBetween(vm.checkin.value, vm.checkout.value))
                                                     {
                                                         classes.push('is-in-range')
                                                     }
                                                     if (vm.checkin.value && current.isSame(vm.checkin.value))
                                                     {
                                                         classes.push('is-checkin-day')
                                                     }
                                                     if (vm.checkout.value && current.isSame(vm.checkout.value))
                                                     {
                                                         classes.push('is-checkout-day')
                                                     }
                                                     return [isSelectable, classes.join(' ')]
                                                 }
                          },
                          ref:              () => 'check' + self.type + 'Input',
                          $elem:            () => $(vm.$refs[self.ref()]),
                          picker:           function ()
                                            {
                                                return self.$elem().datepicker.apply(self.$elem(), Array.prototype.slice.call(arguments))
                                            },
                          toggleDatepicker: () => self.isOpen ? self.picker('hide') : self.picker('show'),
                      }
                      return Object.assign(self, config)
                  }

                  return {
                      destination:             null,
                      destinationSuggestions:  [],
                      destinationSearch:       '',
                      selectedHotelId:         null,
                      selectedLocationId:      null,
                      correspondingBcUrl:      null,
                      checkin:                 datepickerCommon({type: 'in'}),
                      checkout:                datepickerCommon({type: 'out'}),
                      isGuestsPopoverVisible:  false,
                      numAdults:               2,
                      numChildren:             0,
                      childrenAges:            [],
                      isCompareWithBookingcom: true,
                      isFiltersVisible:        false,
                      filtersOpenedCnt:        0,
                      distance:                {
                          value:    50,
                          max:      50,
                          interval: 0.1
                      },
                      rating:                  {
                          value:    0,
                          max:      10,
                          interval: 0.5
                      },
                      stars:                   new Stars,
                      amenities:               new Amenities,
                      propertyTypes:           new PropertyTypes,
                      submitsCount:            0,
                      destinationOptions:      {
                          cities: {
                              label: 'Города',
                              items: []
                          },
                          hotels: {
                              label: 'Отели',
                              items: []
                          }
                      }
                  }
              },
    watch:    {
        'checkin.isPristine': function (val, oldVal)
                              {
                                  if (oldVal === true)
                                  {
                                      this.checkout.picker('show')
                                  }
                              },
        'checkin.value':      function (val, oldVal)
                              {
                                  if (this.checkout.value && this.checkout.value.isBefore(val))
                                  {
                                      this.checkout.value = val.clone().add(1, 'days')
                                      this.checkout.picker('setDate', this.checkout.value.toDate())
                                  }
                              },
        'checkout.value':     function (val, oldVal)
                              {
                                  if (this.checkin.value && this.checkin.value.isAfter(val))
                                  {
                                      this.checkin.value = val.clone().subtract(1, 'days')
                                      this.checkin.picker('setDate', this.checkin.value.toDate())
                                  }
                              }
    },
    computed: {
        guestsBtnLabel()
        {
            let result = 'Взрослые (' + this.numAdults + ')'
            if (this.numChildren)
            {
                result += ', Дети (' + this.numChildren + ')'
            }
            return result
        },
        isValid()
        {
            return this.selectedLocationId || this.selectedHotelId
        },
        isDestinationErrorVisible()
        {
            return (this.submitsCount > 0) && !this.isValid
        },
        guestsPopoverStyle()
        {
            return {
                display: this.isGuestsPopoverVisible ? 'block' : 'none'
            }
        }
    },
    created:  function ()
              {
                  const vm = this
                  if (this.initLocationId && this.initLocationName)
                  {
                      this.destinationSuggestions = [{
                          items: [{
                              id:          this.initLocationId,
                              fullName:    this.initLocationName,
                              hotelsCount: this.initLocationHotelsCount
                          }],
                          key:   'cities',
                          label: 'Города'
                      }]
                      this.destination = this.destinationSuggestions[0].items[0]
                      this.destinationSearch = this.initLocationName
                      this.selectedLocationId = this.initLocationId
                      this.correspondingBcUrl = 'https://booking.com/' + this.initLocationBcUrl
                  }
                  if (this.initHotelgroupName)
                  {
                      [this.propertyTypes, this.stars].forEach(group =>
                          group.items.filter(item =>
                              item.hotelgroup && item.hotelgroup === this.initHotelgroupName
                          )
                              .forEach(item => item.checked = true)
                      )
                  }
              },
    mounted()
    {
        let vm         = this,
            dateFields = ['checkin', 'checkout']
        dateFields.forEach(
            name => $(vm.$refs[name + 'Input']).datepicker(vm[name].config))

        function updateDateFormat()
        {
            if ($(window).innerWidth() < 360)
            {
                dateFields.forEach(field => vm[field].picker('option', 'dateFormat', 'dd M yy'))
            }
            else
            {
                dateFields.forEach(field => vm[field].picker('option', 'dateFormat', 'dd MM yy'))
            }
        }

        $(window).resize(updateDateFormat)
        updateDateFormat()
    },
    methods:  {
        strrepeat(str, cnt)
        {
            let result = ''
            for (let i = 0; i < cnt; i++) result += str
            return result
        },
        getDestinationSuggestions(search, cb)
        {
            axios.get('https://engine.hotellook.com/api/v2/lookup.json', {
                params: {
                    lang:  "ru",
                    limit: 5,
                    query: search
                }
            }).then(function (response)
            {
                let result = []
                if (response.data.results.locations.length)
                {
                    result.push({
                        label: 'Города',
                        key:   'cities',
                        items: response.data.results.locations
                    })
                }
                if (response.data.results.hotels.length)
                {
                    result.push({
                        label: 'Отели',
                        key:   'hotels',
                        items: response.data.results.hotels
                    })
                }
                cb(result)
            })
        },
        onCheckinPikadayReady(pikaday)
        {
            this.checkin.pikaday = pikaday
            pikaday.setMinDate(new Date())
        },
        onCheckoutPikadayReady(pikaday)
        {
            this.checkout.pikaday = pikaday
            pikaday.setMinDate(new Date())
        },
        toggleGuestsPopover()
        {
            this.isGuestsPopoverVisible = !this.isGuestsPopoverVisible
        },
        hideGuestsPopover(e)
        {
            let button = document.getElementById('search-form-guests-popover-button')
            if (button !== e.target && !button.contains(e.target))
            {
                const vm = this
                setTimeout(function ()
                {
                    vm.isGuestsPopoverVisible = false
                })
            }
        },
        adultsDecrease()
        {
            if (this.numAdults > 1)
            {
                this.numAdults--
            }
        },
        adultsIncrease()
        {
            if (this.numAdults < 4)
            {
                this.numAdults++
            }
        },
        childrenDecrease()
        {
            if (this.numChildren > 0)
            {
                this.numChildren--
                this.childrenAges.pop()
            }
        },
        childrenIncrease()
        {
            if (this.numChildren < 3)
            {
                this.numChildren++
                this.childrenAges.push({value: 7})
            }
        },
        childAgeDecrease(index)
        {
            if (this.childrenAges[index].value > 0)
            {
                this.childrenAges[index].value--
            }
        },
        childAgeIncrease(index)
        {
            if (this.childrenAges[index].value < 17)
            {
                this.childrenAges[index].value++
            }
        },
        toggleFilters()
        {
            this.isFiltersVisible = !this.isFiltersVisible
            if (this.isFiltersVisible)
            {
                let vm = this
                setTimeout(function ()
                {
                    vm.$refs.distanceSlider.refresh()
                })
                this.reachGoal('bookingcom_form_filters_opened')
            }
            else
            {
                this.reachGoal('bookingcom_form_filters_closed')
            }
        },
        onDestinationSelect(item)
        {
            this.destination = item
            if (!item)
            {
                this.selectedHotelId = null
                this.selectedLocationId = null
                this.correspondingBcUrl = null
                return
            }
            getCorrespondingBcUrl(item, result =>
            {
                this.correspondingBcUrl = result.success && result.result
            })
            if (item.locationId)
            {
                this.selectedHotelId = item.id
                this.selectedLocationId = null
                this.destinationSearch = item.fullName
            }
            else
            {
                this.selectedHotelId = null
                this.selectedLocationId = item.id
                this.destinationSearch = item.fullName
            }
        },
        onDestinationType(text)
        {
            this.destinationSearch = text
            this.submitsCount = 0
        },
        resetFilters()
        {
            this.distance.value = 50
            this.rating.value = 0
            this.stars.reset()
            this.amenities.reset()
            this.propertyTypes.reset()
        },
        buildHotellookSearchUrl(options = {})
        {
            let din               = this.checkin.value,
                dout              = this.checkout.value,
                selectedStars     = [],
                selectedAmenities = [],
                selectedPropTypes = [],
                url               = {
                    query: [],
                    hash:  []
                }
            if (this.selectedLocationId)
            {
                url.query.push('locationId=' + this.selectedLocationId)
            }
            else
            {
                url.query.push('hotelId=' + this.selectedHotelId)
            }
            if (din)
            {
                url.query.push('checkIn=' + din.year() + '-' +
                    ('0' + (din.month() + 1)).slice(-2) + '-' +
                    ('0' + din.date()).slice(-2))
            }
            if (dout)
            {
                url.query.push('checkOut=' + dout.year() + '-' +
                    ('0' + (dout.month() + 1)).slice(-2) + '-' +
                    ('0' + dout.date()).slice(-2))
            }
            url.query.push('adults=' + this.numAdults)
            url.query.push('children=' + this.childrenAges.map(function (elem)
            {
                return elem.value
            }).join(','))
            url.query.push('dontRedirectToMobile=1')
            url.query.push('language=ru')
            url.query.push('currency=rub')
            url.query.push('marker=76500')
            if (this.isFiltersVisible)
            {
                if (this.distance.value < 50)
                {
                    url.hash.push('f[distance]=' + this.distance.value)
                }
                if (this.rating.value > 0)
                {
                    url.hash.push('f[rating]=' + this.rating.value)
                }
                selectedAmenities = this.amenities.items
                    .filter(item => item.checked && item.htlkKey)
                    .map(item => item.htlkKey)
                if (selectedAmenities.length)
                {
                    url.hash.push('f[amenities]=' + selectedAmenities.join(';'))
                }
                selectedPropTypes = this.propertyTypes.items
                    .filter(item => item.checked && item.htlkKey)
                    .map(item => item.htlkKey)
                selectedStars = this.stars.items
                    .filter(item => item.checked)
                    .map(item => item.value)
            }
            else
            {
                if (this.initHotelgroupName)
                {
                    selectedPropTypes = this.propertyTypes.items
                        .filter(item => item.hotelgroup && item.hotelgroup === this.initHotelgroupName)
                        .map(item => item.htlkKey)
                    selectedStars = this.stars.items
                        .filter(item => item.hotelgroup && item.hotelgroup === this.initHotelgroupName)
                        .map(item => item.value)
                }
            }
            if (selectedPropTypes.length)
            {
                url.hash.push('f[types]=' + selectedPropTypes.join(';'))
            }
            if (selectedStars.length)
            {
                url.hash.push('f[stars]=' + selectedStars.join(';'))
            }
            if (options.showOnMap)
            {
                url.hash.push('l[type]=wide')
            }

            return 'https://search.hotellook.com/?' + url.query.join('&') +
                (url.hash.length ? '#' + url.hash.join('&') : '')
        },
        buildBookingcomSearchUrl(options = {})
        {
            let
                query = [],
                hash  = '',
                base_path,
                isFromPredefinedParams,
                joinQueryWithBaseSymbol,
                result

            query = addDatesFromFormToQuery.call(this, query)
            query = addGuestsFromFormToQuery.call(this, query)

            if (options.showOnMap)
            {

                hash = '#map_opened-map-header-cta'
            }

            isFromPredefinedParams = !!this.initCustomBcQuery && !this.isFiltersVisible &&
                (this.destinationSearch === this.initLocationName)

            if (isFromPredefinedParams)
            {

                for (let i = 0; i < this.initCustomBcQuery.query.length; i++)
                {
                    query.push(this.initCustomBcQuery.query[i])
                }

                for (let i = 0; i < query.length; i++)
                {
                    if (query[i].startsWith('label='))
                    {
                        query[i] = query[i].replace('%', options.showOnMap ? 'map' : 'search')
                    }
                }

                base_path = this.initCustomBcQuery.base + this.initCustomBcQuery.path

                joinQueryWithBaseSymbol = '?'

            }
            else
            {

                query.push('aid=817055')

                let label = 'label=form-%'.replace('%', options.showOnMap ? 'map' : 'search')

                if (this.initCategoryCode)
                {
                    label = label + '-' + this.initCategoryCode
                }
                query.push(label)

                let selectedFilters = []

                selectedFilters = selectedFilters.concat(this.amenities.items
                    .filter(item => item.checked && item.bcKey)
                    .map(item => 'hotelfacility=' + item.bcKey + ';')
                )
                selectedFilters = selectedFilters.concat(this.propertyTypes.items
                    .filter(item => item.checked && item.bcKey)
                    .map(item => 'ht_id=' + item.bcKey + ';')
                )
                selectedFilters = selectedFilters.concat(this.stars.items
                    .filter(item => item.checked)
                    .map(item => 'class=' + item.value + ';')
                )
                if (this.rating.value >= 6)
                {
                    [60, 70, 80, 90].forEach(value =>
                    {
                        if (value >= this.rating.value * 10) selectedFilters.push('review_score=' + value + ';')
                    })
                }

                if (selectedFilters.length)
                {
                    query.push('nflt=' + encodeURIComponent(selectedFilters.join('')))
                }

                base_path = this.correspondingBcUrl ||
                    'https://booking.com/searchresults.ru.html?ss=' + encodeURIComponent(this.destination.fullName)

                joinQueryWithBaseSymbol = '&'
            }

            result = base_path + joinQueryWithBaseSymbol + query.join('&') + hash

            result = utm.appendLabelToUrl(result)

            return result


            function addDatesFromFormToQuery(query)
            {
                let
                    din  = this.checkin.value,
                    dout = this.checkout.value

                if (din)
                {
                    query.push('checkin_monthday=' + din.date())
                    query.push('checkin_month=' + (din.month() + 1))
                    query.push('checkin_year=' + din.year())
                }

                if (dout)
                {
                    query.push('checkout_monthday=' + dout.date())
                    query.push('checkout_month=' + (dout.month() + 1))
                    query.push('checkout_year=' + dout.year())
                }

                return query
            }

            function addGuestsFromFormToQuery(query)
            {

                query.push('group_adults=' + this.numAdults)

                query.push('group_children=' + this.childrenAges.length)
                this.childrenAges.forEach(item => query.push('age=' + item.value))

                return query
            }
        },
        onSearch()
        {
            this.submitsCount++
            if (!this.isValid)
            {
                return
            }
            let url
            if (this.isCompareWithBookingcom)
            {
                this.reachGoal('bookingcom_search_submit')
                url = this.buildBookingcomSearchUrl()
            }
            else
            {
                this.reachGoal('hotellook_search_submit')
                url = this.buildHotellookSearchUrl()
            }
            console.dir(url)

            if ((this.ua.device === 'mobile') && (['yandex', 'firefox'].includes(this.ua.vendor)))
            {
                url = url.replace('://booking.com', '://m.booking.com')
            }

            window.open(url, '_blank')
        },
        onSearchMap()
        {
            this.submitsCount++
            if (!this.isValid)
            {
                return
            }
            let url
            if (this.isCompareWithBookingcom)
            {
                this.reachGoal('bookingcom_search_submit_on_map')
                url = this.buildBookingcomSearchUrl({showOnMap: true})
            }
            else
            {
                this.reachGoal('hotellook_search_submit_on_map')
                url = this.buildHotellookSearchUrl({showOnMap: true})
            }
            console.dir(url)

            if ((this.ua.device === 'mobile') && (['yandex', 'firefox'].includes(this.ua.vendor)))
            {
                url = url.replace('://booking.com', '://m.booking.com')
            }

            window.open(url, '_blank')
        }
    },
    mixins:   [common]
}