export default {
    props: {
        initiallyCollapsed: {
            type:    Boolean,
            default: false
        }
    },
    data() {
        return {
            collapsed: this.initiallyCollapsed
        }
    },
}
