export default {
    data()
    {
        return {
            isExpanded: getCookie('jdmpnn_fhelper_is_expanded') == 1
        }
    },
    methods: {
        onToggleExpanded()
        {
            this.isExpanded = !this.isExpanded

            setCookie('jdmpnn_fhelper_is_expanded', this.isExpanded ? 1 : 0)
        }
    },

}
